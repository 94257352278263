import {createSlice} from 'redux-starter-kit'
import axios from 'axios'
import { notifyUser } from '../../util'
import { LOGIN_URL, GET_USER_URL,LOGOUT_USER_URL,CHANGE_PASSWORD_URL} from '../../urls'
const initialState = {
      user: null,
      loading: false,
      signing: false,
      resetting: false,
      redirect: true,
      token: localStorage.getItem("_token"),
      isAuthenticated: false,
      isPasswordChangeSuccess:false,
      error:""
}
const login = createSlice({
      name: "login",
      initialState: initialState,
      reducers:{
          authenticateStart(state, action){
             state.signing = true
             state.isPasswordChangeSuccess = false
             state.error = ""
          },
          loadUserStart(state,action){
            state.loading = true
            state.isPasswordChangeSuccess = false
            state.error = ""
          },
          cleanErrors(state){
             state.error = ""
          },
          changePasswordStart(state){
             state.resetting = true
             state.error = ""
          },
          changePasswordFailure(state,action){
              state.error = action.payload.message
              state.resetting = false
              
          },
          loadUserSuccess(state, action){
            const { results } = action.payload
              state.user = results
              state.loading = false
              state.signing = false
              state.isAuthenticated = true
              state.loading = false
              
          },
          changePasswordSuccess(state){
              localStorage.removeItem("_token")
              state.isPasswordChangeSuccess = true
              state.resetting = false
              state.isAuthenticated = false
              state.token = null

              notifyUser("success","Password reset","Password was successfully resetted")
          },
          loadUserFailure(state,action){
             state.loading = false
             state.signing = false
             state.isAuthenticated = false
             state.redirect = false
             localStorage.removeItem("_token");
             state.token = null
             state.user = {}
          },
          authenticateSuccess(state, action){
            const { results } = action.payload
              localStorage.setItem("_token", results.token);
              state.user = results.user
              state.token = results.token
              state.signing = false
              state.isAuthenticated = true
              state.loading = false
          },
          authenticateFailure(state, action){
             state.signing = false
             state.isAuthenticated = false
             state.error = action.payload.error
          },
          logout(state, action){
              localStorage.removeItem("_token");
              state.token = null
              state.loading = false
              state.isAuthenticated = false
              state.user = {}
          }
      }
})
export const {
    authenticateStart,
    loadUserStart,
    authenticateSuccess,
    authenticateFailure,
    loadUserSuccess,
    changePasswordStart,
    changePasswordSuccess,
    changePasswordFailure,
    loadUserFailure,
    cleanErrors,
    logout
} = login.actions
export default login.reducer

export const loadUser = () => async (dispatch, getState) =>{
      dispatch(loadUserStart())
    try {
        const response = await axios.get(GET_USER_URL,tokenConfig(getState))
        dispatch(loadUserSuccess({ results: response.data }))
       
    } catch (error) {
        if (error.response ) {
            dispatch(loadUserFailure())
            // dispatch(authenticateFailure({ error: "Error Occured" }))
        } else if (error.request) {
            // dispatch(authenticateFailure({ error: "Network Error" }))
        }
        else {
            dispatch(authenticateFailure({ error: error.message }))
        }
    }
}
export const changePassword = payload => async (dispatch, getState) =>{
    dispatch(changePasswordStart())
    try {
        await axios.post(CHANGE_PASSWORD_URL,payload,tokenConfig(getState))
        dispatch(changePasswordSuccess())
    } catch (error) {
        if (error.response && error.response.data) {
            const data = error.response.data
            if (typeof data === "object"){
                const {message, detail } = data
                dispatch(changePasswordFailure({ message: message ? message : detail }))
            // dispatch(authenticateFailure({ error: "Error Occured" }))
            }else{
                dispatch(changePasswordFailure({ message: "An eror occured try again" }))
            }
           
        } else if (error.request) {
          
            dispatch(changePasswordFailure({ message: "Oops!, Network error" }))
            // dispatch(authenticateFailure({ error: "Network Error" }))
        }
        else {
         
            dispatch(changePasswordFailure({ message: "An error occured try again" }))
        }
    }
}
export const authenticate = user => async dispatch =>{
    try {
        dispatch(authenticateStart())
        const response = await axios.post(LOGIN_URL, user)
        dispatch(authenticateSuccess({ results: response.data}))
       
    } catch (error) {
        if(error.response){
            const { non_field_errors} = error.response.data
            if(non_field_errors){
                dispatch(authenticateFailure({ error: non_field_errors.join(",") }))
            }
           
        }else if(error.request){
        
            dispatch(authenticateFailure({ error: "Network Error" }))
        }
        else{
            dispatch(authenticateFailure({ error: error.message}))
        }
  
    }
}
export const logoutUser = () => async (dispatch, getState) =>{

    try {
        const response = await axios.post(LOGOUT_USER_URL,null,tokenConfig(getState))
        dispatch(logout({results: response.data}))
    } catch (error) {
     
        if(error.response){
            dispatch(loadUserFailure())
         
        }
      
    }
}

// Setup config with token
export const tokenConfig = getState => {
    // Headers
    const config = {
        headers: {
            "Content-Type": "application/json"
        }
    };
    // Get token from state
    const token = getState().login.token;
    if (token) {
        config.headers["Authorization"] = `Token ${token}`;
    }
    return config;
};
export const uploadTokenConfig = getState => {
    // Headers
    const config = {
        headers: {
            "Content-Type": "multipart/form-data"
            
        }
    };
    // Get token from state
    const token = getState().login.token;
    if (token) {
        config.headers["Authorization"] = `Token ${token}`;
    }
    return config;
};
