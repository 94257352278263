import { createSlice } from "redux-starter-kit";
import axios from 'axios'
const initialState = {
    requestLogs: [],
    loading: false,
    count: 0
}
const log = createSlice({
    name:"log",
    initialState: initialState,
    reducers: {
        getRequestLogsStart(state){
            state.loading = true
        },
        getRequestLogsSuccess(state, action){
            state.requestLogs = action.payload.results
            state.count = action.payload.count
            state.loading = false
        },
        getRequestLogsFailure(state, action){
            state.loading = false
        }
    }
})

export const {
  getRequestLogsStart,
  getRequestLogsSuccess,
  getRequestLogsFailure
} =log.actions
export default log.reducer
export const fetchRequestLogs = filters => async dispatch =>{
   dispatch(getRequestLogsStart())
   try {
       const response = await axios.get("/api/tpb-request-logs/",{
           params: filters
       });
       dispatch(getRequestLogsSuccess(response.data))
   } catch (error) {
       dispatch(getRequestLogsFailure())
   }
  
}
