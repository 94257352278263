import { createSlice } from 'redux-starter-kit'
import { errorHandler, notifyUser } from '../util'
import {
    getLeaderships,
    addLeadership,
    updateLeadership,
    deleteLeadership
} from '../services/leadership'
const initialState = {
    loading: false,
    filter: {},
    leaderships: [],
    modal: {
        modalType: "add",
        visible: false,
        currentLeadership: {}
    },
    errors: [],
    count: 0,
}

const leadership = createSlice({
    initialState: initialState,
    name: "leadership",
    reducers: {
        setLoading(state) {
            state.loading = true
        },
        getLeadershipsSuccess(state, action) {
            const { count, leaderships } = action.payload
            state.loading = false
            state.leaderships = leaderships
            state.count = count
        },
        updateLeadershipSuccess(state, action) {
            let description = ""
            state.loading = false
            state.modal.visible = false
            const { leadership, type } = action.payload
            if (type === "update") {
                const leaderships = state.leaderships.map(l => l.id === leadership.id ? leadership : l)
                state.leaderships = leaderships
                description = "One leadership was successfully updated"
            } else if (type === "add") {
                state.leaderships = [...state.leaderships, leadership]
                description = "One leadership was successfully added"
            } else {
                const { leadership_id } = action.payload
                const leaderships = state.leaderships.filter(leadership => leadership.id !== leadership_id)
                state.leaderships = leaderships
                description = "One leadership was successfully deleted"
            }
            notifyUser("success", "Info", description)
        },
        crudeLeadershipsFailure(state, action) {
            state.loading = false
            state.errors = action.payload
            notifyUser("error", "Error", action.payload)
        },
        hideModal(state) {
            state.modal = initialState.modal
        },
        showModal(state, action) {
            state.modal = {
                modalType: action.payload && action.payload.modalType ? action.payload.modalType : "add",
                visible: true,
                currentLeadership: action.payload && action.payload.currentLeadership ? action.payload.currentLeadership : {}
            }
        }
    }
})
export default leadership.reducer

export const {
    setLoading,
    getLeadershipsSuccess,
    updateLeadershipSuccess,
    crudeLeadershipsFailure,
    showModal,
    hideModal
} = leadership.actions
export const fetchLeaderships = (filters = { type: "get" }) => async (dispatch) => {
    dispatch(setLoading())
    try {
        const {type,...rest } = filters
        if (type === "get" || type === "search") {
            const { leaderships, count } = await getLeaderships(rest)
            dispatch(getLeadershipsSuccess({ leaderships, count }))
        } else {

        }
    } catch (error) {
        const err = errorHandler(error)
        dispatch(crudeLeadershipsFailure(err))
    }
}
export const mutateLeaderships = action => async (dispatch) => {
    dispatch(setLoading())
    try {
        if (action.type === "add") {
            const leadership = await addLeadership(action.payload)
            dispatch(updateLeadershipSuccess({ type: "add", leadership: leadership }))
        } else if (action.type === "update") {
            const leadership = await updateLeadership(action.query, action.payload)
            dispatch(updateLeadershipSuccess({ type: "update", leadership: leadership }))
        } else {
            await deleteLeadership(action.query)
            dispatch(updateLeadershipSuccess({ type: "delete", leadership_id: action.query }))
        }

    } catch (error) {
        const err = errorHandler(error)
        dispatch(crudeLeadershipsFailure(err))
    }
}
