import { createSlice } from 'redux-starter-kit'
import { errorHandler, notifyUser } from '../../util'
import { tokenConfig } from '../login/loginSlice'
import moment from 'moment'
import {
    getPayments,
    exportPayments
} from '../../services/payment'
const initialState = {
    loading: false,
    filter: {},
    payments: [],
    exporting: false,
    errors: [],
    count: 0,
}

const payment = createSlice({
    initialState: initialState,
    name: "payment",
    reducers: {
        setLoading(state) {
            state.loading = true
        },
        setExporting(state) {
            state.exporting = true
        },
        getPaymentsSuccess(state, action) {
            const { count, payments } = action.payload
            state.loading = false
            state.payments = payments
            state.count = count
        },
        crudePaymentsFailure(state, action) {
            state.loading = false
            state.errors = action.payload
            notifyUser("error", "Error", action.payload)
        },
        exportPaymentSuccess(state){
            state.exporting = false
        },
        exportPaymentFailure(state){
            state.exporting = false
        }
    }
})
export default payment.reducer

export const {
    setLoading,
    setExporting,
    getPaymentsSuccess,
    crudePaymentsFailure,
    exportPaymentSuccess,
    exportPaymentFailure
} = payment.actions
export const fetchPayments = (filters) => async (dispatch,getState) => {
    dispatch(setLoading())
    try {
          const { payments, count } = await getPayments(filters,tokenConfig(getState))
          dispatch(getPaymentsSuccess({ payments, count }))
    } catch (error) {
        const err = errorHandler(error)
        dispatch(crudePaymentsFailure(err))
    }
}
export const handleExportPayments = payload => async (dispatch, getState) => {
    dispatch(setExporting())
    try {
        const data = await exportPayments(payload, tokenConfig(getState))
        const url = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement("a")
        link.href = url;
        link.setAttribute('download', `payments-${moment()}.xlsx`);
        document.body.appendChild(link);
        link.click();
        dispatch(exportPaymentSuccess())
    }catch (error) {
        const err = errorHandler(error)
        dispatch(exportPaymentFailure())
        dispatch(crudePaymentsFailure(err))
    }
}
