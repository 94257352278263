import axios from 'axios'
import {
    APPLICATION_URL,EXPORT_APPLICATIONS_URL,IDCARDS_URL
} from '../urls'



export async function getApplications(filters,config) {
    const { data } = await axios.get(APPLICATION_URL,{
        params: filters,
        headers: config.headers,
        crossDomain: true
        
    })
    const { count, results } = data
    return {
        count: count,
        apps: results
    }
}
export async function getCompletedApps(filters,config) {
    const res = await axios.get(IDCARDS_URL,{
        params: filters,
        config,
    })
    return res.data
}
export async function exportApps(payload, config) {
    const response = await axios.get(EXPORT_APPLICATIONS_URL,{
        headers: config.headers,
        params: payload,
        responseType: 'blob'

    })
    return response.data
}
export async function bulkApprove(level, payload, config) {
    let url = null
    if (level === "national level") {
      url = "/api/applications/approve_national/";
    } else {
      url = "/api/applications/regional_approval/";
    }
  const res = await axios.post(url, payload, config);
  return res.data
}


