import { createSlice} from 'redux-starter-kit'
import axios from 'axios'
import {  MEMBER_DASHBOARD_URL} from '../../../urls'
import { tokenConfig} from '../../../pages/login/loginSlice'
import { Constant } from '../../../util'
const { Color } = Constant
const initialState = {
    summary: [
        {
            icon: 'alert',
            color: Color.red,
            title: 'Apps in Progress',
            key: "application_in_progress",
            number: 0,
        },
        {
            icon: 'team',
            color: Color.red,
            title: 'Active Members',
            key: "active_members",
            number: 0,
        },
        {
            icon: 'clock-circle',
            color: Color.red,
            title: 'Expires in',
            key: "expires_in",
            number: 0,
        },
        {
            icon: 'wallet',
            color: Color.red,
            title: 'Amount Due',
            key:"amount_due",
            number: 0,
        },
    ],
    loading: false
}
const memberDashboard = createSlice({
    name:"memberDashboard",
    initialState: initialState,
    reducers:{
        getSummaryStart(state, action){
            state.loading = true
        },
        getSummarySuccess(state,action){
            const {results} = action.payload
         
            const numbers = state.summary.map(item => item ? { ...item, number: results[item.key] } : item)
            state.summary = numbers
            state.loading = false
        },
        getSummaryFailure(state, action){
            state.loading = false
        }
    }

})
export const {
   getSummaryFailure,
   getSummaryStart,
   getSummarySuccess
} = memberDashboard.actions

export default memberDashboard.reducer
export const fetchSummary = () => async (dispatch, getState) =>{
    dispatch(getSummaryStart())
    try {
         const response = await axios.get(MEMBER_DASHBOARD_URL, tokenConfig(getState))
         dispatch(getSummarySuccess({results: response.data}))
    } catch (error) {
         dispatch(getSummaryFailure())
    }
}