import {createSlice} from 'redux-starter-kit'
import { errorHandler, notifyUser} from '../util'
import {
     getRegions,
     getResidences,
     searchRegions,
     addRegion,
     updateRegion,
     deleteRegion
    } from '../services/location'
const initialState = {
    loading: false,
    filter: {},
    regions:[],
    residences:[],
    modal: {
       modalType:"add",
       visible: false,
       currentRegion:{}
    },
    errors: [],
    count: 0,
}

const region = createSlice({
    initialState: initialState,
    name: "region",
    reducers:{
        setLoading(state){
           state.loading = true
        },
        getRegionsSuccess(state,action){
            const {count, regions} = action.payload
           state.loading = false
           state.regions = regions
           state.count = count
        },
        getResidencesSuccess(state, action) {
            const { residences } = action.payload
            state.residences = residences
        },
        updateRegionSuccess(state,action){
            let description = ""
            state.loading = false
            state.modal.visible = false
            const {region,type } = action.payload
            if(type === "update"){
                const regions = state.regions.map(reg => reg.region_code === region.region_code ? region : reg )
                state.regions = regions
                description = "One region was successfully updated"
            }else if (type === "add"){
                state.regions = [...state.regions, region]
                description = "One region was successfully added"
            }else{
                const { region_code } = action.payload
               const regions = state.regions.filter(reg => reg.region_code !== region_code)
               state.regions = regions
               description = "One region was successfully deleted"
            }
            notifyUser("success", "Info",description)
        },
        crudeRegionsFailure(state,action){
            state.loading = false
            state.errors = action.payload
            notifyUser("error","Error",action.payload)
        },
        hideModal(state){
            state.modal = initialState.modal
        },
        showModal(state,action){
            state.modal = {
                modalType: action.payload && action.payload.modalType ? action.payload.modalType : "add",
                visible: true,
                currentRegion: action.payload && action.payload.currentRegion ? action.payload.currentRegion : {}
            } 
        }
    }
})
export default region.reducer

export const {
 setLoading,
 getRegionsSuccess,
 getResidencesSuccess,
 updateRegionSuccess,
 crudeRegionsFailure,
 showModal,
 hideModal
} =  region.actions
export const  fetchRegions = (filters = {type:"get"}) => async (dispatch)=>{
       dispatch(setLoading())
     try {
         if(filters.type === "get"){
             const { regions, count } = await getRegions(filters)
             dispatch(getRegionsSuccess({ regions, count }))
         }else if(filters.type === "search"){
             const { regions, count } = await searchRegions(filters.query)
             dispatch(getRegionsSuccess({ regions, count }))
         }else{

         }
     } catch (error) {
         const err = errorHandler(error)
         dispatch(crudeRegionsFailure(err))
     }
    }
export const mutateRegions= action => async (dispatch) =>{
    dispatch(setLoading())
    try {
        if (action.type === "add"){
            const region = await addRegion(action.payload)
            dispatch(updateRegionSuccess({type:"add",region}))
        }else if(action.type === "update"){
            const region = await updateRegion(action.query,action.payload)
            dispatch(updateRegionSuccess({type:"update",region: region}))
        }else{
            await deleteRegion(action.query)
            dispatch(updateRegionSuccess({ type: "delete", region_code: action.query }))
        }
      
    } catch (error) {
        const err = errorHandler(error)
        dispatch(crudeRegionsFailure(err))
    }
}
export const fetchResidences = () => async dispatch =>{
    try {
        const residences = await getResidences()
        dispatch(getResidencesSuccess({residences: residences}))
    } catch (error) {
        const err = errorHandler(error)
        dispatch(crudeRegionsFailure(err))
    }
}
  