import axios from 'axios'
import { LEADERSHIP_URL} from '../urls'
// Leadership API
export async function addLeadership(payload) {
    const { data } = await axios.post(LEADERSHIP_URL, payload)
    return data
}
export async function getLeaderships() {
    const { data } = await axios.get(LEADERSHIP_URL)
    const { results, count } = data
    return {
        leaderships: results,
        count: count
    }
}
export async function updateLeadership(id, payload) {
    const { data } = await axios.put(`${LEADERSHIP_URL}${id}/`, payload)
    return data
}
export async function deleteLeadership(query) {
    const { data } = await axios.delete(`${LEADERSHIP_URL}${query}/`)
    return {
        leadership: data
    }
}