import { createSlice} from 'redux-starter-kit'
import { MEMBER_ACTIONS } from  '../pages/dashboard/admin/adminSlice'
const initialState = {
    timeFilter: "this_week",
    locationFilter: {label: 'All', value: 'all' },
    memberFilter: 'count_by_gender',
    timeFilters :[
        {
            label:"This week",
            value: "this_week"
        },
        {
            "label": "Last week",
            "value":"last_week"
        },
        {
            "label":"This Month",
            "value":"this_month"
        },
        {
            "label":"Last Month",
            "value":"last_month"
        },
        {
            "label":"This Quarter",
            "value":"this_quarter"

        },
        {
            "label":"Last Quarter",
            "value":"last_quarter"

        },
        {
            "label":"This year",
            "value":"this_year"

        },
        {
            "label":"Last year",
            "value":"last_year"

        }
    ],
    locationFilters: [
        {
            mode: "all",
            label: "All",
            value:"all",
            region: null,
            district: null,
            ward: null,
            branch: null,
            subBranch: null
        }
    ],
    memberFilters: [
        {"label": "Filter by gender", value: MEMBER_ACTIONS.MEMBER_GENDER},
        { "label": "Filter by employment status", value: MEMBER_ACTIONS.MEMBER_EMPLOYMENT_STATUS},
        { "label": "Filter by blood group", value: MEMBER_ACTIONS.MEMBER_BLOOD_GROUP},
        { "label": "Filter by blood leadership", value: MEMBER_ACTIONS.MEMBER_LEADERSHIP},
        { "label": "Filter by trcs experience", value: MEMBER_ACTIONS.MEMBER_TRCS_EXPERIENCE},
        { "label": "Filter by education level", value: MEMBER_ACTIONS.MEMBER_EDUCATION_LEVEL},
    ]

}
const visibilityFilter = createSlice({
    name:"visibilityFilter",
    initialState: initialState,
    reducers:{
        addFilter(state, action){
            console.log(action.payload)
            state.locationFilters.push(action.payload)
        },
        removeFilter(state, action){
          state.filters = state.filters.filter(filter => filter.value !== action.paylaod)
        },
        clearAll(state, action){
            state.locationFilters = initialState.locationFilters
            state.locationFilter = 'all'
            state.timeFilter = 'this_week'
        },
        setFilter(state, action){
            const {timeFilter, locationFilter,memberFilter} = action.payload
            if(timeFilter){
                state.timeFilter = timeFilter
            }if(memberFilter){
                state.memberFilter = memberFilter
            }
            if(locationFilter){
                if(locationFilter === "all"){
                    state.locationFilter = {}
                }else{
                    state.locationFilter = state.locationFilters.find(filter => filter.value === locationFilter)
                }
                
            }
          

        }
       
    }
})
export default visibilityFilter.reducer
export const {
   addFilter,
   removeFilter,
   clearAll,
   setFilter,
} = visibilityFilter.actions
