import { createSlice } from 'redux-starter-kit'
import { errorHandler, notifyUser, } from '../util'
import { tokenConfig} from '../pages/login/loginSlice'
import moment from 'moment'

import {
   addBranch,
   getBranches,
   updateBranch,
   searchBranches,
   deleteBranch,
   exportBranches
} from '../services/branch'
const initialState = {
    loading: false,
    filter: {},
    exporting:false,
    branches: [],
    modal: {
        modalType: "add",
        visible: false,
        currentBranch: {}
    },
    errors: [],
    count: 0,
}

const branch = createSlice({
    initialState: initialState,
    name: "branch",
    reducers: {
        setLoading(state) {
            state.loading = true
        },
        setExporting(state) {
            state.exporting = true
        },
        getBranchesSuccess(state, action) {
            const { count, branches } = action.payload
            state.loading = false
            state.branches = branches
            state.count = count
        },
        exportBranchesSuccess(state, action) {
            state.exporting = false
        },
        updateBranchSuccess(state, action) {
            let description = ""
            state.loading = false
            state.modal.visible = false
            const { branch, type } = action.payload
            if (type === "update") {
                const branches = state.branches.map(b => b.id === branch.id ? branch : b)
                state.branches = branches
                description = "One branch was successfully updated"
            } else if (type === "add") {
                state.branches = [...state.branches, branch]
                description = "One branch was successfully added"
            } else {
                const { branch_id } = action.payload
                const branches = state.branches.filter(branch => branch.id !== branch_id)
                state.branches = branches
                description = "One branch was successfully deleted"
            }
            notifyUser("success", "Info", description)
        },
        crudeBranchesFailure(state, action) {
            state.loading = false
            state.errors = action.payload
            notifyUser("error", "Error", action.payload)
        },
        hideModal(state) {
            state.modal = initialState.modal
        },
        showModal(state, action) {
            state.modal = {
                modalType: action.payload && action.payload.modalType ? action.payload.modalType : "add",
                visible: true,
                currentBranch: action.payload && action.payload.currentBranch ? action.payload.currentBranch : {}
            }
        }
    }
})
export default branch.reducer

export const {
    setLoading,
    setExporting,
    getBranchesSuccess,
    exportBranchesSuccess,
    updateBranchSuccess,
    crudeBranchesFailure,
    showModal,
    hideModal
} = branch.actions
export const fetchBranches = (filters = { type: "get" }) => async (dispatch) => {
    dispatch(setLoading())
    try {
        const { type,query, ...rest } = filters
        if (type === "get") {
            const { branches, count } = await getBranches(rest)
            dispatch(getBranchesSuccess({ branches, count }))
        } else if (type === "search") {
            const { branches, count } = await searchBranches(query)
            dispatch(getBranchesSuccess({ branches, count }))
        } else {

        }
    } catch (error) {
        const err = errorHandler(error)
        dispatch(crudeBranchesFailure(err))
    }
}
export const mutateBranches = action => async (dispatch) => {
    dispatch(setLoading())
    try {
        if (action.type === "add") {
            const branch = await addBranch(action.payload)
            dispatch(updateBranchSuccess({ type: "add", branch: branch }))
        } else if (action.type === "update") {
            const branch = await updateBranch(action.query, action.payload)
            dispatch(updateBranchSuccess({ type: "update", branch: branch }))
        } else {
            await deleteBranch(action.query)
            dispatch(updateBranchSuccess({ type: "delete", branch_id: action.query }))
        }

    } catch (error) {
        const err = errorHandler(error)
        dispatch(crudeBranchesFailure(err))
    }
}
export const handleExportBranches = payload => async (dispatch, getState) => {
    dispatch(setExporting())
    try {
        const data = await exportBranches(payload, tokenConfig(getState))
        const url = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement("a")
        link.href = url;
        link.setAttribute('download', `branches-${moment()}.xlsx`);
        document.body.appendChild(link);
        link.click();
        dispatch(exportBranchesSuccess())
    } catch (error) {
        const err = errorHandler(error)
        dispatch(crudeBranchesFailure(err))
    }
}
