import { createSlice } from 'redux-starter-kit'
import { errorHandler, notifyUser } from '../util'
import {
    getTrcsExperiences,
    getExperiences,
    addTrcsExperience,
    updateTrcsExperience,
    deleteTrcsExperience
} from '../services/experience'
const initialState = {
    loading: false,
    filter: {},
    experiences: [],
    trcs_experiences: [],
    modal: {
        modalType: "add",
        visible: false,
        currentExperience: null
    },
    errors: [],
    count: 0,
}

const experience = createSlice({
    initialState: initialState,
    name: "experience",
    reducers: {
        setLoading(state) {
            state.loading = true
        },
        getExperiencesSuccess(state, action) {
            const { experiences } = action.payload
            state.loading = false
            state.experiences = experiences

        },
        getTrcsExperiencesSuccess(state, action) {
            const { count, trcs_experiences } = action.payload
            state.loading = false
            state.trcs_experiences = trcs_experiences
            state.count = count
        },
        updateExperienceSuccess(state, action) {
            let description = ""
            state.loading = false
            state.modal.visible = false
            const { trcs_experience, type } = action.payload
            if (type === "update") {
                const experiences = state.experiences.map(exp => exp.id === experience.id ? experience : exp)
                state.experiences = experiences
                description = "One experience was successfully updated"
            } else if (type === "add") {
                state.trcs_experiences = [...state.trcs_experiences, trcs_experience]
                description = "One Trcs experience was successfully added"
            } else {
                const { trcs_experience_id } = action.payload
                const trcs_experiences = state.trcs_experiences.filter(experience => experience.id !== trcs_experience_id)
                state.trcs_experiences = trcs_experiences
                description = "One experience was successfully deleted"
            }
            notifyUser("success", "Info", description)
        },
        crudeExperiencesFailure(state, action) {
            state.loading = false
            state.errors = action.payload
            notifyUser("error", "Error", action.payload)
        },
        hideModal(state) {
            state.modal = initialState.modal
        },
        showModal(state, action) {
            state.modal = {
                modalType: action.payload && action.payload.modalType ? action.payload.modalType : "add",
                visible: true,
                currentExperience: action.payload && action.payload.currentExperience ? action.payload.currentExperience : {}
            }
        }
    }
})
export default experience.reducer

export const {
    setLoading,
    getExperiencesSuccess,
    getTrcsExperiencesSuccess,
    updateExperienceSuccess,
    crudeExperiencesFailure,
    showModal,
    hideModal
} = experience.actions
export const fetchExperiences = () => async (dispatch) =>{
   dispatch(setLoading())
   try {
       const { experiences } = await getExperiences()
       dispatch(getExperiencesSuccess({experiences: experiences}))
   } catch (error) {
       
   }
}
export const fetchTrcsExperiences = (filters = { type: "get" }) => async (dispatch) => {
    dispatch(setLoading())
    try {
        const {type,...rest} = filters
        if (type === "get"  || type === "search"  ) {
            const { trcs_experiences, count } = await getTrcsExperiences(rest)
            dispatch(getTrcsExperiencesSuccess({ trcs_experiences, count }))
        } else {

        }
    } catch (error) {
        const err = errorHandler(error)
        dispatch(crudeExperiencesFailure(err))
    }
}
export const mutateTrcsExperiences = (action,cb) => async (dispatch) => {
    dispatch(setLoading())
    try {
        if (action.type === "add") {
            const trcs_experience = await addTrcsExperience(action.payload)
            dispatch(updateExperienceSuccess({ type: "add", trcs_experience: trcs_experience }))
            cb()
        } else if (action.type === "update") {
            const experience = await updateTrcsExperience(action.query, action.payload)
            dispatch(updateExperienceSuccess({ type: "update", experience: experience }))
    
        } else {
            await deleteTrcsExperience(action.query)
            dispatch(updateExperienceSuccess({ type: "delete", trcs_experience_id: action.query }))
        }

    } catch (error) {
        const err = errorHandler(error)
        dispatch(crudeExperiencesFailure(err))
    }
}
