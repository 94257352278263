import { createSlice } from 'redux-starter-kit'
import axios from 'axios'
import { BEGIN_PASSWORD_RESET,PASSWORD_RESET} from '../../urls'
import { notifyUser, errorHandler} from '../../util'
const initialState = {
    loading: false,
    resending: false,
    user:{
        isValid: false,
        session_token: null,
        phone_string: null,
        username: null
    },
    isSuccess: false,
    errors:[]
}
const accounts = createSlice({
    name:'account',
    initialState: initialState,
    reducers:{
        isFetching(state,action){
            state.loading = true
            state.user.username = action.payload.username
        },
        verifyStart(state,action){
          state.loading = true
          state.user.security_code = action.payload.security_code
        },
        resetStart(state){
          state.loading = true
        },
        isReseding(state){
          state.loading = true
        },
        fetchUserSuccess(state,action){
           state.loading = false
           state.user.session_token = action.payload.session_token
           state.user.phone_string = action.payload.phone_string
           state.errors = []
        },
        fetchUserFailure(state,action){
          state.loading = false
          state.user.username = null
          state.user.phone_string = null
          state.user.session_token = null
          state.errors = action.payload.error
        
        //   state.user = initialState.user
        },
        resetPasswordSuccess(state,action){
            state.user.username = null
            state.user.session_token = null
            state.isSuccess = true
            state.isSuccess = true;
            state.loading = false
            state.errors = []
           
        },
        resetPasswordFailure(state,action){
            state.isSuccess = false
            state.errors = action.payload.errors
            state.loading = false
            // state.resending = false
        }
    }
})

export const {
   isFetching,
   fetchUserSuccess,
   fetchUserFailure,
   resetPasswordSuccess,
   resetStart,
   isReseding,
   resetPasswordFailure
} = accounts.actions
export default accounts.reducer

export const fetchUser = payload => async dispatch =>{
    dispatch(isFetching({ username:payload.username}))
    try {
       const res = await axios.post(BEGIN_PASSWORD_RESET,payload)
       const data = res.data
        dispatch(fetchUserSuccess({ session_token: data.session_token, phone_string: data.phone_string}))
    } catch (error) {
         let msg = []
        if (error.response && error.response.data){
            const data = error.response.data
            if (typeof data === "object"){
               const keys = Object.keys(data)
               keys.forEach(key =>{
                   const temp = data[key]
                   msg.push(`${temp.join(',')}`)
               })
            }else{
                msg = ["Oop! An error occured try again"]
            }
        }else{
            msg = ["Oop! Failed to connect the server"]
        }
        dispatch(fetchUserFailure({ error: msg })) 
       
    }
}
export const resendCode = payload => async dispatch =>{
    dispatch(isReseding({ username:payload.username}))
    try {
       const res = await axios.post(BEGIN_PASSWORD_RESET,payload)
        dispatch(fetchUserSuccess({ ...res.data}))
    } catch (error) {
         let msg = ""
        if (error.response && error.response.data){
            const { non_field_errors } = error.response.data
              if (non_field_errors){
                  msg = non_field_errors.join(",")
              }
            dispatch(fetchUserFailure({error: [msg] }))
        }
          
       
    }
}
export const resetPassword = payload => async dispatch =>{
    dispatch(resetStart())
    try {
       const res = await axios.post(PASSWORD_RESET,payload)
        dispatch(resetPasswordSuccess({ ...res.data}))
    } catch (error) {
        const  err = errorHandler(error)
        dispatch(resetPasswordFailure({errors: err}))  
    }
}