import axios from 'axios'

import {
    PAYMENTS_URL,
    EXPORT_PAYMENTS_URL
} from '../urls'



export async function getPayments(filters,config) {
    const { data } = await axios.get(PAYMENTS_URL,{
        params: filters,
        headers: config.headers
    })
    const { count, results } = data
    return {
        count: count,
        payments: results
    }
}
export async function searchPayments(query,token) {
    const { data } = await axios.get(`${PAYMENTS_URL}?search=${query}`)
    const { results, count } = data
    return {
        payments: results,
        count: count
    }
}
export async function exportPayments(payload, config) {
    const response = await axios.get(EXPORT_PAYMENTS_URL, {
        headers: config.headers,
        params: payload,
        responseType: 'blob'

    })
    return response.data
}

