import { createSlice } from 'redux-starter-kit'
import { errorHandler, notifyUser } from '../util'
import { tokenConfig } from '../pages/login/loginSlice'
import {

    getPlans,
    searchPlans,
    addPlan,
    updatePlan,
    deletePlan
} from '../services/plan'
const initialState = {
    loading: false,
    filter: {},
    plans: [],
    modal: {
        modalType: "add",
        visible: false,
        currentPlan: {}
    },
    errors: [],
    count: 0,
}

const plan = createSlice({
    initialState: initialState,
    name: "plan",
    reducers: {
        setLoading(state) {
            state.loading = true
        },
        getPlansSuccess(state, action) {
            const { count, plans } = action.payload
            state.loading = false
            state.plans = plans
            state.count = count
        },
        updatePlanSuccess(state, action) {
            let description = ""
            state.loading = false
            state.modal.visible = false
            const { plan, type } = action.payload
            if (type === "update") {
                const plans = state.plans.map(p => p.id === plan.id ? plan : p)
                state.plans = plans
                description = "One plan was successfully updated"
            } else if (type === "add") {
                state.plans = [...state.plans, plan]
                description = "One plan was successfully added"
            } else {
                const { plan_id } = action.payload
                const plans = state.plans.filter(plan => plan.id !== plan_id)
                state.plans = plans
                description = "One plan was successfully deleted"
            }
            notifyUser("success", "Info", description)
        },
        crudePlansFailure(state, action) {
            state.loading = false
            state.errors = action.payload
            notifyUser("error", "Error", action.payload)
        },
        hideModal(state) {
            state.modal = initialState.modal
        },
        showModal(state, action) {
            state.modal = {
                modalType: action.payload && action.payload.modalType ? action.payload.modalType : "add",
                visible: true,
                currentPlan: action.payload && action.payload.currentPlan ? action.payload.currentPlan : {}
            }
        }
    }
})
export default plan.reducer

export const {
    setLoading,
    getPlansSuccess,
    updatePlanSuccess,
    crudePlansFailure,
    showModal,
    hideModal
} = plan.actions
export const fetchPlans = (filters = { type: "get" }) => async (dispatch, getState) => {
    dispatch(setLoading())
    try {
        if (filters.type === "get") {
            const { plans, count } = await getPlans(filters,tokenConfig(getState))
            dispatch(getPlansSuccess({ plans, count }))
        } else if (filters.type === "search") {
            const { plans, count } = await searchPlans(filters.query)
            dispatch(getPlansSuccess({ plans, count }))
        } else {

        }
    } catch (error) {
        const err = errorHandler(error)
        dispatch(crudePlansFailure(err))
    }
}
export const mutatePlans = action => async (dispatch,getState) => {
    dispatch(setLoading())
    try {
        if (action.type === "add") {
            const plan = await addPlan(action.payload,tokenConfig(getState))
            dispatch(updatePlanSuccess({ type: "add", plan: plan }))
        } else if (action.type === "update") {
            const plan = await updatePlan(action.query, action.payload,tokenConfig(getState))
            dispatch(updatePlanSuccess({ type: "update", plan:plan }))
        } else {
            await deletePlan(action.query)
            dispatch(updatePlanSuccess({ type: "delete", plan_id: action.query }))
        }

    } catch (error) {
        const err = errorHandler(error)
        dispatch(crudePlansFailure(err))
    }
}
