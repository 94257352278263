import { createSlice} from 'redux-starter-kit'
import axios from 'axios'
import { NOTIFICATION_URL} from '../urls'
import { tokenConfig} from '../pages/login/loginSlice'
import { errorHandler, notifyUser} from '../util'
const initialState = {
    loading: false,
    sending: false,
    notifications: [],
    pages: 1,
}
const notification = createSlice({
    name:"notification",
    initialState: initialState,
    reducers:{
           getNotificationStart(state){
            state.loading = false
           },
           getNotificationSuccess(state,action){
               console.log(action.payload)
               const {results, count} = action.payload.data
               state.loading = false
               state.notifications = results
               state.pages = count
           },
           getNotificationFailure(state,action){
    
               state.loading = false
           },
           loadMoreStart(state){
              state.loading = true
           },
           loadMoreSuccess(state,action){
               const { results, count } = action.payload.results
               state.loading = false
               state.nofications.push(results)
               state.pages = count
           },
           loadMoreFailure(state,action){
               
           },
           sendNotificationStart(state){
              state.sending =  true
           },
           sendNotificationSuccess(state, action){
               state.sending = false
           },
           sendNotificationFailure(state, action){
               state.sending = false
           }
    }

})

export const {
   getNotificationStart,
   getNotificationSuccess,
   getNotificationFailure,
   sendNotificationStart,
   sendNotificationFailure,
   sendNotificationSuccess
} = notification.actions

export default notification.reducer

export const fetchNotifications = (filters) =>  async (dispatch, getState) =>{
    dispatch(getNotificationStart())
    try {
        const response = await axios.get(NOTIFICATION_URL, {
          params: filters,
          headers: tokenConfig(getState).headers,
        });
        dispatch(getNotificationSuccess({ data: response.data }))
    } catch (error) {
      dispatch(getNotificationFailure())
    }
   
}
export const loadMore = (payload) => async(dispatch, getState) =>{

}
export const sendNotification = (payload) =>  async (dispatch, getState) =>{
    dispatch(sendNotificationStart())
    try {
        await axios.post("/api/notifications/resend_sms/",payload,tokenConfig(getState))
        dispatch(sendNotificationSuccess())
        return Promise.resolve()
    } catch (error) {
        const err = errorHandler(error)
        notifyUser("error","Error",err[0])
       dispatch(sendNotificationFailure())
      return Promise.resolve();
    }
}