import axios from "axios";
export async function getOrders(filters, config) {
  console.log(config)
  const { data } = await axios.get('/api/orders', {
    params: filters,
    headers: config.headers
  });

  return data

}
export async function getOrderLines(order_id, config) {
  const res = await axios.get(`/api/orders/${order_id}/lines`, {
    ...config,
  });

  return res.data;
}
export async function getSubscriptions(filters, config) {
    const { data } = await axios.get("/api/subscription/", {
      params: filters,
      headers: config.headers,
    });
    return data

}
export async function postOrder(payload, config) {
    const { data } = await axios.post("/api/orders/",payload, config);
    return data

}
export async function putOrder(order_id,payload, config) {
    const { data } = await axios.put(`/api/orders/${order_id}/`,payload, config);
    return data

}
