import { createSlice } from 'redux-starter-kit'
import { errorHandler, notifyUser } from '../util'
import {
    getDistricts,
    searchDistricts,
    addDistrict,
    updateDistrict,
    deleteDistrict
} from '../services/location'
const initialState = {
    loading: false,
    filter: {},
    districts: [],
    modal: {
        modalType: "add",
        visible: false,
        currentDistrict: {}
    },
    errors: [],
    count: 0,
}

const district = createSlice({
    initialState: initialState,
    name: "district",
    reducers: {
        setLoading(state) {
            state.loading = true
        },
        getDistrictsSuccess(state, action) {
            const { count, districts } = action.payload
            state.loading = false
            state.districts = districts
            state.count = count
        },
        updateDistrictsSuccess(state, action) {
            let description = ""
            state.loading = false
            state.modal.visible = false
            const { district, type } = action.payload
            if (type === "update") {
                const districts = state.districts.map(dis => dis.id === district.id ? district : dis)
                state.districts = districts
                description = "One district was successfully updated"
            } else if (type === "add") {
                state.districts = [...state.districts, district]
                description = "One district was successfully added"
            } else {
                const { district_id } = action.payload
                const districts = state.districts.filter(dis => dis.id !== district_id)
                state.districts = districts
                description = "One district was successfully deleted"
            }
            notifyUser("success", "Info", description)
        },
        crudeDistrictFailure(state, action) {
            state.loading = false
            state.errors = action.payload
            notifyUser("error", "Error", action.payload)
        },
        hideModal(state) {
            state.modal = initialState.modal
        },
        showModal(state, action) {
            state.modal = {
                modalType: action.payload && action.payload.modalType ? action.payload.modalType : "add",
                visible: true,
                currentDistrict: action.payload && action.payload.currentDistrict ? action.payload.currentDistrict : {}
            }
        }
    }
})
export default district.reducer

export const {
    setLoading,
    getDistrictsSuccess,
    updateDistrictsSuccess,
    crudeDistrictFailure,
    showModal,
    hideModal
} = district.actions
export const fetchDistricts= (filters = { type: "get" }) => async (dispatch) => {
    dispatch(setLoading())
    try {
        const {type, ...rest} = filters
        if (type === "get") {
            const { districts, count } = await getDistricts(rest)
            dispatch(getDistrictsSuccess({ districts, count }))
        } else if (type === "search") {
            const { districts, count } = await searchDistricts(rest.query)
            dispatch(getDistrictsSuccess({ districts, count }))
        } else {

        }
    } catch (error) {
        const err = errorHandler(error)
        dispatch(crudeDistrictFailure(err))
    }
}
export const mutateDistricts = action => async (dispatch) => {
    dispatch(setLoading())
    try {
        if (action.type === "add") {
            const district = await addDistrict(action.payload)
            dispatch(updateDistrictsSuccess({ type: "add", district }))
        } else if (action.type === "update") {
            const district = await updateDistrict(action.query, action.payload)
            dispatch(updateDistrictsSuccess({ type: "update", district }))
        } else {
            await deleteDistrict(action.query)
            dispatch(updateDistrictsSuccess({ type: "delete", district_id: action.query }))
        }

    } catch (error) {
        const err = errorHandler(error)
        dispatch(crudeDistrictFailure(err))
    }
}
