import React from 'react'
import { Spin } from 'antd';
import './index.css'
const Loader = () => {
    return (
        <div className="example" style={{ flex: 1 }}>
            <Spin style={{ marginTop: '50px' }} />
        </div>
    )
}
export default Loader
