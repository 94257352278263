import React,{useEffect} from 'react'
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Input, Button, Card, Row, Col, Avatar, Alert, Layout, Form } from 'antd';
import { Link, navigate} from '@reach/router' 
import { useDispatch,useSelector } from 'react-redux'
import { authenticate, cleanErrors } from './loginSlice'
import logo from '../../assets/logo.jpg'
import { MEMBER_REGISTRATION_ROUTE, PASSWORD_RESET } from '../../navigations'
import Title from 'antd/lib/typography/Title';
const { Content } = Layout

const LoginPage = (props) =>{
   const dispatch = useDispatch()
   const {user,signing, isAuthenticated,error} = useSelector(state => state.login)
    const redirect = () =>{
        if (user.is_member) {
            navigate("/member/dashboard")
        } else if (user.is_admin || user.is_subBranch || user.is_branch) {
            navigate("/admin/dashboard")
        }
         else {

        }
    }

   useEffect(() =>{
      dispatch(cleanErrors())
      return () =>{
          dispatch(cleanErrors())
      }
   },[])
   const  handleSubmit = values => {
       dispatch(authenticate(values))
    }
    if (isAuthenticated){
        redirect()
    }
    return(
        <Layout>
            <Content style={{
                margin: '2',
                padding: 24,
                background: '#EFF1F4',
                minHeight: '100vh'
            }}>
                <Row type="flex" justify="center" gutter={24}  >
                    <Col lg={8} md={24}>
                        <div style={{ width: "100%", height: 100 }}>
                            {!signing && error ?
                                <Alert
                                    message="Error"
                                    description={error}
                                    type="error"
                                    showIcon
                                    closable
                                    className="alert"

                                /> : null
                            }
                        </div>

                        <Card bordered={true} >
                            <div style={{ marginBottom: '10px', textAlign: "center" }} >
                                <Row type="flex" justify="center">
                                    <Col>
                                        <Avatar src={logo} size={75} />
                                    </Col>
                                </Row>
                                <Row type="flex" justify="center">
                                    Tanzania Red Cross Society
                                </Row>
                                <Row type="flex" justify="center">
                                    <Title level={4}>
                                        Membership Management System
                                        </Title>
                                </Row>
                            </div>

                            <Form
                                name="Sign In Form"
                                onFinish={handleSubmit}
                                layout="vertical">
                                <Form.Item
                                    name="username"
                                    rules={[{ required: true, message: 'Please input your username!' }]}
                                >
                                    <Input
                                        prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                        placeholder="Username"

                                    />
                                </Form.Item>
                                <Form.Item
                                    name="password"
                                    rules={[{ required: true, message: 'Please input your Password!' }]}
                                >
                                    <Input
                                        prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                        type="password"
                                        placeholder="Password"
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <Row gutter={24} type="flex" justify="space-between">
                                        <Col>
                                            <Button type="primary" htmlType="submit" className="login-form-button" loading={signing}>
                                                Sign in
                                            </Button>
                                        </Col>
                                        <Col>

                                            <Button type="primary" className="login-form-button" >
                                                <a href="http://www.trcs.or.tz" >
                                                    Back Home
                                                    </a>
                                            </Button>

                                        </Col>
                                    </Row>


                                </Form.Item>
                                <div>
                                    <Link to={MEMBER_REGISTRATION_ROUTE}>
                                        Register now!
                                        </Link>
                                    <Link style={{ float: "right" }} to={PASSWORD_RESET}>
                                        Forgot Password
                                       </Link>
                                </div>
                            </Form>
                        </Card>
                        <Card>
                            <div style={{ textAlign: "center" }}>
                                For Support PleaseCall us at <b> +255765788535 </b> <br />
                                    SMS  <b> +255765788535 </b> WhatsApp  <b> +255765788535 </b><br />
                                    Telegram  <b> +255765788535 </b>
                                    or Email us at  <b>support@trcs.or.tz </b><br />
                                    Tanzania Red Cross Society © 2020, All Rights Reserved.
                            </div>

                        </Card>
                    </Col>
                </Row>
            </Content>

        </Layout>
    )
}
export default LoginPage