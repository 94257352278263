import { createSlice } from 'redux-starter-kit'
import { errorHandler, notifyUser } from '../util'
import {
 getSubscriptions,
    postMembershipGenerationRequest,
    postUpgradeMembership,
    getDocuments
} from '../services/subscription'
import { tokenConfig } from '../pages/login/loginSlice'
const initialState = {
    loading: false,
    upgrading: false,
    generating_card: false,
    hash: null,
    filter: {},
    subscriptions: [],
    documents: [],
    errors: [],
    count: 0,
}

const subscription = createSlice({
    initialState: initialState,
    name: "subscription",
    reducers: {
        setLoading(state) {
            state.loading = true
        },
        setGeneratingCard(state){
            state.generating_card = true
        },
        getSubscriptionSuccess(state, action) {
            const { count, subscriptions } = action.payload
            state.loading = false
            state.subscriptions = subscriptions
            state.count = count
        },
        getSubscriptionFailure(state){
            state.loading = false
            notifyUser("error", "Error!","Could not fetch subscription,try refresing the page")
        },
        generateMembershipCardStart(state){
            state.generating_card = true
        },
        generateMembershipCardSuccess(state, action){
            state.generating_card = false
            state.hash = action.payload
        },
        generateMembershipCardFailure(state,action){
            state.generating_card = false
            state.errors = [action.payload]
        },
        upgradeMembershipStart(state){
            state.upgrading = true
        },
        upgradeMemberSuccess(state,action){
            const {subscriptions, ...rest} = action.payload
            state.upgrading = false
            state.subscriptions = action.payload.subscriptions
            state.upgradeResponse = action.rest
            notifyUser("success","Upgrade Success","Membership plan was successfull upgraded")
        },
        upgradeMemberFailure(state,action){
            state.upgrading = false
        },
        getDocumentSuccess(state, action){
            const {results, count} = action.payload
            state.loading = false
            state.documents = results
        },
        getDocumentFailure(state,action){
            state.loading = false
        }
    }
})
export default subscription.reducer

export const {
    setLoading,
     getSubscriptionSuccess,
     getSubscriptionFailure,
     generateMembershipCardStart,
     generateMembershipCardSuccess,
     generateMembershipCardFailure,
     upgradeMembershipStart,
     upgradeMemberSuccess,
     upgradeMemberFailure,
     getDocumentSuccess,
     getDocumentFailure
} = subscription.actions
export const fetchSubscriptions = filters => async (dispatch,getState) => {
    dispatch(setLoading())
    try {
         const { subscriptions, count } = await getSubscriptions(filters,tokenConfig(getState))
         dispatch(getSubscriptionSuccess({ subscriptions, count }))
    } catch (error) {
        const err = errorHandler(error)
        dispatch(getSubscriptionFailure(err))
    }
}

export const generateMembershipCard = payload  => async(dispatch,getState) =>{
    dispatch(generateMembershipCardStart())
    try {
        const res = await postMembershipGenerationRequest(payload, tokenConfig(getState))
        dispatch(generateMembershipCardSuccess(res))
    } catch (error) {
        if(error && error.response)
             dispatch(generateMembershipCardFailure(error.response.data))
             return Promise.reject()
    }
}
export const upgradeMembership = payload => async(dispatch, getState) =>{
    dispatch(upgradeMembershipStart())
    try {
        const res = await postUpgradeMembership(payload,tokenConfig(getState))
        dispatch(upgradeMemberSuccess(res))
    } catch (error) {
        if(error && error.response){
            console.log(error.response.data)
        }
        dispatch(upgradeMemberFailure())
    }
}
export const fetchDocuments = filters => async(dispatch, getState) =>{
    dispatch(setLoading())
    try {
        const res = await getDocuments(filters, tokenConfig(getState))
        dispatch(getDocumentSuccess(res))
    } catch (error) {
        if (error && error.response) {
            console.log(error.response.data)
        }
        dispatch(getDocumentFailure())
    }
}
