import { createSlice } from 'redux-starter-kit'
import { errorHandler, notifyUser } from '../util'
import { uploadTokenConfig } from '../pages/login/loginSlice'
import moment from 'moment'
import {
    addMember,
    updateMember,
    deleteMember,
    getMembers,
    approveMember,
    bulkApproval,
    uploadFile,
    exportMembers,
    getImportMemberSampleFile
} from '../services/member'

import { tokenConfig } from '../pages/login/loginSlice'
const initialState = {
    loading: false,
    exporting: false,
    approving:false,
    isSuccess: false,
    redirect: false,
    filter: {},
    members: [],
    approvals:[],
    currentMember:null,
    modal: {
        modalType: "add",
        visible: false,
        currentMember: {}
    },
    uploadModal:{
        visible: false
    },
    errors: [],
    uploadErrors:[],
    count: 0,
    num_approvals:0,
    uploading: false
}

const member = createSlice({
    initialState: initialState,
    name: "member",
    reducers: {
        setLoading(state) {
            state.loading = true
        },
        setApproving(state) {
            state.approving = true
        },
        setExporting(state) {
            state.exporting = true
        },
        getMembersSuccess(state, action) {
            const { count, members } = action.payload
            
            state.loading = false
            state.members = members
            state.count = count
        },
        exportMemberSuccess(state){
            state.exporting = false
    
        },
        exportMemberFailure(state){
            state.exporting = false
    
        },
        getCurrentMemberSuccess(state, action) {
            const { member } = action.payload
            state.loading = false
            state.currentMember = member
        },
        getApprovalSuccess(state, action) {
            const { count, approvals } = action.payload
            state.loading = false
            state.approvals = approvals
            state.num_approvals = count
        },
        bulkApprovalSuccess(state, action) {
            const { approvals,message } = action.payload
            state.loading = false
            state.approvals = approvals
            let desc = `${message} Members were successfully Approved` 
            notifyUser("success","Bulk Approval Success",desc)
        },
        approveSuccess(state,action){
           state.approving = false
           state.currentMember = action.payload.member
        },
        updateMemberSuccess(state, action) {
            let description = ""
            state.loading = false
          
            const { member, type } = action.payload
            const updated_member = {
                ...member,
                trcs_experience: member ? member.updated_trcs_experiences: null
            }
            if (type === "update") {
               
                const members = state.members.map(m => m.id === member.id ? updated_member : m)
                state.members = members

                state.currentMember = updated_member
                description = "One member was successfully updated"
            } else if (type === "add") {
                state.isSuccess = true
                state.modal.visible = false
                state.members = [...state.members, updated_member]
                description = "One member was successfully added"
            } else {
                const { member_id } = action.payload
                const members = state.members.filter(member => member.id !== member_id)
                state.members = members
                description = "One member was successfully deleted"
            }
            notifyUser("success", "Info", description)
        },
        crudeMembersFailure(state, action) {
            state.loading = false
            state.approving = false
            state.errors = action.payload
            notifyUser("error", "Error", action.payload)
        },
        hideModal(state) {
            state.modal = initialState.modal
        },
        hideUploadModal(state) {
            state.uploadModal = initialState.uploadModal
        },
        showUploadModal(state) {
            state.uploadModal.visible = true 
            state.uploadErrors = []
        },
        showModal(state, action) {
            state.modal = {
                modalType: action.payload && action.payload.modalType ? action.payload.modalType : "add",
                visible: true,
            }
           state.currentMember = action.payload && action.payload.currentMember ?{...action.payload.currentMember,phone:action.payload.currentMember.phone.substring(3) } : {}
        },
        setRedirect(state,action){
            state.currentMember = action.payload
            state.redirect = true
        },
        resetRedirect(state){
            state.redirect = false
         
        },
        resetSuccess(state){
            state.isSuccess = false
        },
        resetCurrentMember(state){
          state.currentMember = null
       
        },
        uploadFileStart(state){
            state.uploading = true
        },
        uploadFileSuccess(state,action) {
            state.uploading = false
            state.errors = false
            const msg = `${action.payload.count} members were successfully uploaded.`
            notifyUser("success","Member Uploaded",msg)
        },
        uploadFileFailure(state,action) {
            const { type,message,errors} = action.payload
            state.uploading = false
            if (type === "invalid_headers_err" || type === "invalid_format_err") {
                notifyUser("error", "Error", message)
            } else if (type === "validation_err") {
                  state.uploadErrors = errors
            }else{
                notifyUser("error","Error","An eror occured, try again")
            }
        }
    }
})
export default member.reducer

export const {
    setLoading,
    setApproving,
    setExporting,
    getMembersSuccess,
    updateMemberSuccess,
    crudeMembersFailure,
    showModal,
    hideModal,
    hideUploadModal,
    showUploadModal,
    setRedirect,
    resetRedirect,
    resetCurrentMember,
    getApprovalSuccess,
    exportMemberSuccess,
    exportMemberFailure,
    bulkApprovalSuccess,
    approveSuccess,
    uploadFileStart,
    uploadFileSuccess,
    uploadFileFailure,
    getCurrentMemberSuccess,
    resetSuccess
} = member.actions
export const fetchMembers = (filters = { type: "get" }) => async (dispatch,getState) => {
    dispatch(setLoading())
    try {
        const {type, ...rest} =  filters
        if (type === "get" || "search") {
          
            const { members, count } = await getMembers(rest,tokenConfig(getState))
            if (rest.pending){
                dispatch(getApprovalSuccess({ approvals: members, count }))
            }else{
                dispatch(getMembersSuccess({ members, count }))
            }
         
        } else {

        }
    } catch (error) {
        const err = errorHandler(error)
        dispatch(crudeMembersFailure(err))
    }
}
export const mutateMembers = action => async (dispatch,getState) => {
    dispatch(setLoading())
    try {
        if (action.type === "add") {
            let payload = action.payload
            let config = {}
            const {file,user, ...rest} = action.payload
            if(file){
                let formData = new FormData()
                formData.append("user.email", user.email)
                formData.append("user.password", user.password)
                formData.append("user.username", user.username)
                formData.append("user.avatar", file)
                const keys = Object.keys(rest)
                keys.forEach(key => {
                    if (key === "trcs_experience") {
                        let exp = rest[key]
                        for (var i = 0; i < exp.length; i++) {
                            formData.append('trcs_experience', exp[i]);
                        }
                      
                    }else{
                           formData.append(key, rest[key])
                    } 
                })
               config = {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }} 
                payload = formData
            }
            const member = await addMember(payload,config)
            dispatch(updateMemberSuccess({ type: "add", member: member }))
        } else if (action.type === "update") {
            let payload = action.payload
            let config = {}
            const { file, user, ...rest } = action.payload
            if (file) {
                let formData = new FormData()
                // formData.append("user.email", user.email)
                // formData.append("user.password", user.password)
                // formData.append("user.username", user.username)
                formData.append("avatar", file)
                const keys = Object.keys(rest)
                keys.forEach(key => {
                    if (key === "trcs_experience") {
                        let exp = rest[key]
                        for (var i = 0; i < exp.length; i++) {
                            formData.append('trcs_experience', exp[i]);
                        }
                    } else {
                        formData.append(key, rest[key])
                    }
                })
                config = uploadTokenConfig(getState)
                payload = formData
            }
            config = tokenConfig(getState)
            const member = await updateMember(action.query, payload,config)
            dispatch(updateMemberSuccess({ type: "update", member: member }))
        }else if (action.type === "delete"){
            await deleteMember(action.query, uploadTokenConfig(getState))
            dispatch(updateMemberSuccess({ type: "delete", member_id: action.query }))
        }
        else {
          
        }

    } catch (error) {
        const err = errorHandler(error)
        dispatch(crudeMembersFailure(err))
    }
}
export const handleExportMembers = payload => async(dispatch,getState) =>{
    dispatch(setExporting())
    try {
        const data = await exportMembers(payload,tokenConfig(getState))
        const url = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement("a")
        link.href = url;
        link.setAttribute('download', `members-${moment()}.xlsx`);
        document.body.appendChild(link);
        link.click();
        dispatch(exportMemberSuccess())
    } catch (error) {
        dispatch(exportMemberFailure())
    }
}
export const approve = payload => async (dispatch, getState) => {
    dispatch(setApproving())
    try {
          const { member,status,type } = await approveMember(payload,tokenConfig(getState))
          
          if(type === "national"){
              if (status === 0) {
                  dispatch(approveSuccess({ member: member }))
              } else {
                  notifyUser("error", "Payment request failed", "Failed to initiate TPB payment request,try again.")
              }
          }else{
              dispatch(approveSuccess({ member: member }))
          }
         
       
    } catch (error) {
        const err = errorHandler(error)
        dispatch(crudeMembersFailure(err))
    }
}
export const approveSelected = payload => async (dispatch, getState) => {
    dispatch(setApproving())
    try {
        const { approvals, type, message } = await bulkApproval(payload,tokenConfig(getState))
          if(type === "national"){
            dispatch(bulkApprovalSuccess({ approvals: approvals, message:message }))
          }else{
              dispatch(bulkApprovalSuccess({ approvals: approvals,message }))
          }
         
       
    } catch (error) {
        const err = errorHandler(error)
        dispatch(crudeMembersFailure(err))
    }
}
export const upload  = payload => async(dispatch,getState) =>{
      let formData = new FormData()
      dispatch(uploadFileStart())
      formData.append("file", payload);
      try {
          const user = getState().login.user
          const is_admin = user && user.is_admin
          const {count} = await uploadFile(is_admin,formData,uploadTokenConfig(getState))
          dispatch(uploadFileSuccess({count:count}))
      } catch (error) {
           if(error.response && error.response.data){
               dispatch(uploadFileFailure(error.response.data))
           }
      }
}
export const fetchCurrentMember = () => async (dispatch,getState) =>{
      dispatch(setLoading())
     try {
         const {members }=  await  getMembers(null, tokenConfig(getState))
         dispatch(getCurrentMemberSuccess({ member: members[0]}))
     } catch (error) {
         const err = errorHandler(error)
         dispatch(crudeMembersFailure(err))
     }
}
export const downloadImportMemberSampleFile = () => async(dispatch, getState) =>{
    try {
        const user = getState().login.user;
        const response = await getImportMemberSampleFile(user.is_admin, tokenConfig(getState))
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `members-import-sample-${moment()}.xls`);
        document.body.appendChild(link);
        link.click();
    } catch (error) {
        
    }
}