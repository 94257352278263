import axios from 'axios'
import { REGISTER_MEMBER_URL, MEMBER_URL,APPROVAL_MEMBER_URL,BULK_APPROVAL_URL,EXPORT_MEMBERS_URL} from '../urls'

export async function getMembers(filters,config) {
    const { data } = await axios.get(MEMBER_URL,{
        params:filters,
        headers: config.headers
    })
    const { count, results } = data
    return {
        count: count,
        members: results
    }
}
export async function addMember(payload,config) {
    const { data } = await axios.post(REGISTER_MEMBER_URL, payload,config)
    return data
}
export async function updateMember(id, payload,config) {
    const { data } = await axios.put(`${REGISTER_MEMBER_URL}${id}/`, payload,config)
    return data
}
export async function approveMember(payload,config) {
    const { data } = await axios.post(APPROVAL_MEMBER_URL,payload,config)
    const { member,status,type} = data
    return {
        member: member,
        status:status,
        type: type
    }
}
export async function deleteMember(member_id,config){
    const response = await axios.delete(`${REGISTER_MEMBER_URL}${member_id}/`,config)
    return response.data
}
export async function bulkApproval(payload,config) {
    const res = await axios.post(BULK_APPROVAL_URL,payload,config)
    const { members, status, type, message} = res.data
    return {
        approvals: members,
        status:status,
        type: type,
        message:message
    }
}

export async function exportMembers(payload,config){
    const response = await axios.get(EXPORT_MEMBERS_URL,{
        headers: config.headers,
        params:payload,
        responseType:'blob'
        
    })
    return response.data
}
export async function uploadFile(is_admin, payload, config) {
  const url = is_admin
    ? "/api/next-member-api/import_members/"
    : "/api/next-member-api/import_new_members/";
  const response = await axios.post(url, payload, {
    headers: config.headers,
  });
  return response.data;
}
export async function getImportMemberSampleFile(is_admin, config){
    let url = is_admin
      ? "/api/next-member-api/import_members"
      : "/api/next-member-api/import_new_members";
    const response = await axios.get(url, {
      headers: config.headers,
      responseType: "blob",
    });
    return response.data
}
