import React,{useEffect,useState}  from 'react';
import {Router,Location} from '@reach/router'
import Loadable from 'react-loadable'
import Component from '@reactions/component'
import { useDispatch, useSelector} from 'react-redux'
import { loadUser } from './pages/login/loginSlice'
import './i18n';
import './App.css'
import Loading from './components/loaders/Loader';
import MemberRoute from './components/common/MemberRoute';
import { loadProgressBar } from "axios-progress-bar";
import "axios-progress-bar/dist/nprogress.css";



// import LogRocket from 'logrocket';
// LogRocket.init('edibjm/trcs');
// Listen for changes to the current location.
const AsyncRequestLogPage = Loadable({
  loader: () => import("./pages/logs/ReqeustLogPage"),
  loading: () => null
})
const AsyncDashboard = Loadable({
  loader: () => import("./pages/dashboard/admin"),
  loading: Loading
})
const AsyncMember = Loadable({
  loader: () => import("./pages/members"),
  loading: Loading
})
const AsyncEmployee = Loadable({
  loader: () => import("./pages/employees"),
  loading: Loading
})
const AsyncApplication = Loadable({
  loader: () => import("./pages/applications/admin"),
  loading: Loading
})
const AsyncApproval = Loadable({
  loader: () => import("./pages/applications/admin/ApprovalPage"),
  loading: Loading
})
const AsyncPayments = Loadable({
  loader: () => import("./pages/payments"),
  loading: Loading
})

const AsyncBranches = Loadable({
  loader: () => import("./pages/branches"),
  loading: Loading
})
const AsyncSubBranches = Loadable({
  loader: () => import("./pages/sub_branches"),
  loading: Loading
})

const AsyncRegions = Loadable({
  loader: () => import("./pages/regions"),
  loading: Loading
})
const AsyncWards = Loadable({
  loader: () => import("./pages/wards"),
  loading: Loading
})
const AsyncDistricts = Loadable({
  loader: () => import("./pages/districts"),
  loading: Loading
})
const AsyncLeadership = Loadable({
  loader: () => import("./pages/leaderships"),
  loading: Loading
})
const AsyncExperience = Loadable({
  loader: () => import("./pages/experiences"),
  loading: Loading
})
const AsyncPlans = Loadable({
  loader: () => import("./pages/plans"),
  loading: Loading
})
const AsyncLogin = Loadable({
  loader: () => import("./pages/login"),
  loading: Loading
})
const AsyncRegister = Loadable({
   loader: () => import("./pages/registration"),
   loading: Loading
})
const AsyncPasswordReset = Loadable({
  loader: () => import("./pages/accounts"),
  loading: Loading
})
// const AsycChangePassword = Loadable({
//   loader: () => import("./pages/change-password"),
//   loading: Loading

// })
const AsyncNotFound = Loadable({
  loader: () => import("./components/common/NotFound"),
  loading: Loading
})
const AsyncAdminDashboardHome = Loadable({
  loader: () => import("./AdminHome"),
  loading: Loading
})
const AsyncAdminRoute = Loadable({
  loader: () => import("./components/common/AdminRoute"),
  loading: Loading
})
const AsyncCoordinatorRoute = Loadable({
   loader: () => import("./components/common/CoordinatorRoute"),
   loading: Loading
})
const AsyncSubscription = Loadable({
  loader: () => import("./pages/id-card"),
  loading: Loading
})
const AsyncDocument = Loadable({
  loader: () => import("./pages/document"),
  loading: Loading
})
const AsyncMemberDetail = Loadable({
  loader: () => import("./pages/members/detail"),
  loading: Loading 
})
const AsyncApprovalDetail = Loadable({
  loader: () => import("./pages/approval/detail"),
  loading: Loading
})
const AsyncMemberHomeLayout = Loadable({
  loader: () => import("./layouts/MemberHomeLayout"),
  loading: Loading
})
const AsyncMemberPayments = Loadable({
  loader: () => import("./pages/payments"),
  loading: () => Loading
})
const AsyncMemberApplication = Loadable({
  loader: () => import("./pages/applications/member"),
  loading: () => Loading
})
const AsyncMemberProfile = Loadable({
  loader: () => import("./pages/profile"),
  loading: Loading
})
const AsyncMemberSubscription = Loadable({
  loader: () => import("./pages/id-card/member"),
  loading: Loading
})
const AsyncMemberDashbaord = Loadable({
  loader: () => import("./pages/dashboard/member"),
  loading: () => Loading
})
const AsyncChangePassword = Loadable({
  loader: () => import("./pages/change-password"),
  loading: Loading,

})
const AsyncOrderPage = Loadable({
  loader: () => import("./pages/cards/OrderPage"),
  loading: Loading
})
const AsyncOrderDetailPage = Loadable({
  loader: () => import("./pages/cards/OrderDetailPage"),
  loading: Loading
})
const AsyncCardDetailsPage = Loadable({
  loader: () => import("./pages/members/MemberProfile"),
  loading: () => null
})
const AsyncNotificationPage = Loadable({
  loader: () => import("./pages/notifications/NotificationPage"),
  loading: () => null
})
const TrackPageViews = ({onTrack}) => (
  <Location>
    {({ location }) => (
      <Component
        location={location}
        didMount={() => onTrack(location.pathname)}
        didUpdate={({ prevProps }) => {
          if (prevProps.location !== location) {
            onTrack(location.pathname)
          }
        }}
      />
    )}
  </Location>
)
function App() {
  const dispatch = useDispatch()
  const [currentPath, setCurrentPath] = useState("")
  const loading = useSelector(state =>state.login.loading)
  useEffect(() =>{
      loadProgressBar();
  },[])
  useEffect(() => {
    dispatch(loadUser())
  }, [])
  if(loading){
    return <Loading/>;
  }
  const track = pathname => {
      setCurrentPath(pathname)
  }
  return (
    <>
      <TrackPageViews onTrack={track} />
      <Router>
        <AsyncAdminDashboardHome path="admin" selectedKey={currentPath}>
          <AsyncCoordinatorRoute as={AsyncDashboard} path="dashboard" />
          <AsyncCoordinatorRoute as={AsyncMember} path="members" />
          <AsyncCoordinatorRoute as={AsyncMemberDetail} path="members/detail" />
          <AsyncAdminRoute as={AsyncEmployee} path="employees" />
          <AsyncCoordinatorRoute as={AsyncApplication} path="applications" />
          <AsyncCoordinatorRoute as={AsyncApproval} path="approval" />
          <AsyncCoordinatorRoute
            as={AsyncApprovalDetail}
            path="approval/detail"
          />
          <AsyncCoordinatorRoute as={AsyncPayments} path="payments" />
          <AsyncAdminRoute as={AsyncRegions} path="regions" />
          <AsyncAdminRoute as={AsyncBranches} path="branches" />
          <AsyncAdminRoute as={AsyncSubBranches} path="sub-branches" />
          <AsyncAdminRoute as={AsyncWards} path="wards" />
          <AsyncAdminRoute as={AsyncDistricts} path="districts" />
          <AsyncAdminRoute as={AsyncLeadership} path="leadership" />
          <AsyncAdminRoute as={AsyncExperience} path="experience" />
          <AsyncAdminRoute as={AsyncPlans} path="plans" />
          <AsyncAdminRoute as={AsyncRequestLogPage} path="request-logs" />
          {/* <AsycChangePassword path="change-password" /> */}
          <AsyncAdminRoute as={AsyncSubscription} path="subscriptions" />
          <AsyncAdminRoute as={AsyncDocument} path="cards/documents" />
          <AsyncOrderPage path="cards/orders" />
          <AsyncOrderDetailPage path="cards/orders/detail/:orderId" />
          <AsyncNotificationPage path="notifications" />
          <AsyncNotFound path="/*" />
        </AsyncAdminDashboardHome>
        <AsyncLogin path="login" />
        <AsyncRegister path="registration" />
        <AsyncLogin path="/" />
        <AsyncPasswordReset path="password-reset" />
        <AsyncNotFound path="/*" />
        <MemberRoute
          as={AsyncMemberHomeLayout}
          path="member"
          selectedKey={currentPath}
        >
          <AsyncMemberDashbaord path="dashboard" />
          <AsyncMemberPayments path="payments" />
          <AsyncMemberApplication path="applications" />
          <AsyncMemberProfile path="profile" />
          <AsyncMemberSubscription path="subscriptions" />
          <AsyncChangePassword path="change-password" />
        </MemberRoute>
        <AsyncCardDetailsPage path="verification/:cardNumber" />
      </Router>
    </>
  );
}
export default App;
