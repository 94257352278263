import { BRANCH_URL,SUB_BRANCH_URL,EMPLOYEE_URL} from '../../urls'
import {errorHandler,notifyUser} from '../../util'
import { createSlice} from 'redux-starter-kit'
import {tokenConfig} from '../../pages/login/loginSlice'
import axios from 'axios'
const initialState = {
    employees: [],
    branches:[],
    sub_branches:[],
    loading: false,
    adding: false,
    currentEmployee: null,
    modal:{
        visible: false,
        type: "add"
    },
    import_modal:{
        visible: false
    },
    error: []
}
const employees = createSlice({
    name: 'employees',
    initialState,
    reducers: {
        crudeEmployeeStart(state) {
            state.adding = true
            state.error = []
        },
        addEmployeeSuccess(state, action){
            const { data } = action.payload
            state.adding = false
            state.modal = initialState.modal
            state.employees.push(data)
            notifyUser("success","Added","One employee was added successfully")
        },
        editEmployeeSuccess(state, action){
            const { data } = action.payload
            state.adding = false
            state.modal = initialState.modal
            const updated_employees = state.employees.map(emp => emp.id === data.id ? data : emp)
            state.employees = updated_employees
            notifyUser("success","Updated","One employee was  successfully updated!")
        },
        crudeEmployeeFailure(state,action){
            state.adding = false
            state.error = action.payload.err
        },
        getEmployeeStart(state) {
            state.loading = true
            state.error = []
        },
        getEmployeeSuccess(state, action) {
            const { results } = action.payload
            state.employees = results
            state.loading = false
            state.error = []
        },
        getEmployeeFailure(state, action) {
            const { err} = action.payload
            state.loading = false
            state.error = err
        },
        getBranchesStart(state) {
            state.loading = true
        },
        getBranchesSuccess(state, action) {
            const { results} = action.payload
            state.loading = false
            state.branches = results
        },
        getBranchesFailure(state, action) {
            state.loading = false
        },
        getSubBranchesStart(state) {
            state.loading = true
        },
        getSubBranchesSuccess(state, action) {
            const { results, count } = action.payload
            state.loading = false
            state.sub_branches = results
            state.count = count
        },
        getSubBranchesFailure(state, action) {
            state.loading = false
        },
        showModal(state, action) {
            state.modal = {
                modalType: action.payload && action.payload.modalType ? action.payload.modalType : "add",
                visible: true,
            }
            state.currentEmployee = action.payload && action.payload.currentEmployee ? action.payload.currentEmployee : null
        },
        hideModal(state,action){
          state.modal.visible = false
        },
        showImportModal(state,action){
          state.import_modal.visible = true
        },
        hideImportModal(state,action){
          state.import_modal.visible = false
        }
    }
})
export const {
    crudeEmployeeStart,
    addEmployeeSuccess,
    editEmployeeSuccess,
    crudeEmployeeFailure,
    getEmployeeStart,
    getEmployeeSuccess,
    getEmployeeFailure,
    getBranchesStart,
    getBranchesSuccess,
    getBranchesFailure,
    getSubBranchesStart,
    getSubBranchesFailure,
    getSubBranchesSuccess,
    showModal,
    hideModal,
    showImportModal,
    hideImportModal
} = employees.actions
export default employees.reducer
export const fetchEmployees = query => async(dispatch,getState) => {
    try {
        dispatch(getEmployeeStart())
        let employees = null
        if(query){
             employees = await axios.get(`${EMPLOYEE_URL}?search=${query}`,tokenConfig(getState))  
        }else{
            employees = await axios.get(EMPLOYEE_URL, tokenConfig(getState));
        }
      
        const {results }= employees.data
        dispatch(getEmployeeSuccess({ results }))
    } catch (err) {
        if(err.response){
            dispatch(getEmployeeFailure({ err:  err.response.data }))
        }
    }
}
export const addEmployee = params => async (dispatch, getState) =>{
    try {
        dispatch(crudeEmployeeStart())
        const response = await axios.post(`${EMPLOYEE_URL}`,params,tokenConfig(getState))
        const {data} = response
        dispatch(addEmployeeSuccess({ data }))
    } catch (err) {
        if (err.response) {
            const errors = errorHandler(err)
            dispatch(crudeEmployeeFailure({ err: errors }))
        }
    }
}
export const editEmployee = (employee_id, payload) => async (dispatch, getState) =>{
    try {
        dispatch(crudeEmployeeStart())
        const response = await axios.put(`${EMPLOYEE_URL}${employee_id}/`,payload,tokenConfig(getState))
        const {data} = response
        dispatch(editEmployeeSuccess({ data }))
    } catch (err) {
        if (err.response && err.response.data) {
            const errors = errorHandler(err)
            dispatch(crudeEmployeeFailure({ err: errors }))
        }
    }
}
export const fetchBranches = (param = {}) => async dispatch => {
    dispatch(getBranchesStart())
    try {
        const response = await axios.get(BRANCH_URL, {
            params: param
        })
        const { results, count } = response.data
        dispatch(getBranchesSuccess({ results: results, count: count }))
    } catch (error) {
        if (error.response) {
            dispatch(getBranchesFailure({ error: error.response.data }))
        }

    }
}
export const fetchSubBranches = branch_id => async dispatch => {
    try {
        const response = await axios.get(`${SUB_BRANCH_URL}?branch__id=${branch_id}`)
        const { results } = response.data
        dispatch(getSubBranchesSuccess({ results: results }))
    } catch (error) {
        dispatch(getSubBranchesFailure({ error: "" }))

    }
}
