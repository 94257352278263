import axios from 'axios'
import {
  PLANS_URL
} from '../urls'



export async function getPlans(filters,confing){
    console.log(confing)
    const {data} = await axios.get(PLANS_URL,{
        params: filters,
        headers: confing.headers
    })
    const { count, results} = data
    return{
        count: count,
        plans: results
    }
}
export async function addPlan(payload,confing){
    const { data } = await axios.post(PLANS_URL, payload,confing)
    return data
}
export async function updatePlan(id, payload,config) {
    console.log(config)
    const { data } = await axios.put(`${PLANS_URL}${id}/`, payload, config)
    return data
}
export async function searchPlans(query) {
    const { data } = await axios.get(`${PLANS_URL}?search=${query}`)
    const { results, count } = data
    return {
        plans: results,
        count: count
    }
}
export async function deletePlan(query) {
    const { data } = await axios.delete(`${PLANS_URL}${query}/`)
    return {
        plans: data
    }
}