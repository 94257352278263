import React from 'react'
import { connect } from "react-redux";
import LoginPage from '../../pages/login'
class MemberRoute extends React.Component {
    render() {
        let { as: Comp, user, isAuthenticated, ...props } = this.props;
        if (user && user.is_member) {
            return <Comp {...props} />
        } else if (isAuthenticated) {
            return <div>You dont have permission to view this page</div>
        } else {
            return <LoginPage />
        }
    }
}
const mapStateToProps = state => ({
    user: state.login.user,
    isAuthenticated: state.login.isAuthenticated
});
export default connect(mapStateToProps)(MemberRoute);