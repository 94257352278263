import { createSlice } from 'redux-starter-kit'
import axios from 'axios'
import { tokenConfig } from '../../login/loginSlice'
import { 
    APPLICATION_DASHBOARD_URL,
    MEMBER_DASHBAORD_URL,
    EDUCATIONAL_LEVEL_DASHBOARD_URL,
    TRCS_EXPERIENCE_DASHBOARD_URL,
    CARD_ISSUED_REPORT_URL,
} from '../../../urls'
import { Constant } from '../../../util'
const { Color } = Constant
export const  APPLICATION_ACTIONS = {
    "PAYMENTS_BY_CARD_TYPE":"payments_by_card_type",
    "PAYMENTS_BY_REGION": "payments_by_region",
    "PAYMENTS_BY_DAY":"sum_by_day",
    "APPLICATION_COUNT": "count",
    "PAYMENTS_DUE":"amount_due"
}
export const PAYMENT_ACTIONS = {
    "PAYMENT_COUNT": "count"
}

export const MEMBER_ACTIONS = {
    'MEMBER_GENDER': "count_by_gender",
    "MEMBER_COUNT": "count",
    'MEMBER_COUNT_BY_DAY': "count_by_day",
    'MEMBER_EMPLOYMENT_STATUS': 'count_by_employment_status',
    'MEMBER_BLOOD_GROUP': "count_by_blood_group",
    'MEMBER_TRCS_EXPERIENCE': "count_by_trcs_experience",
    'MEMBER_EDUCATION_LEVEL': "count_by_education_level",
    'MEMBER_LEADERSHIP': "count_by_leadership",
}
export const CARD_ISSUED_ACTIONS ={
    COUNT_BY_DAY  : "count_by_day",
    COUNT_BY_CARD_TYPE: "count_by_card_type"

}
const initialState = {
    pending: [], 
    settingVisible: false,
    memberRatioData: [],
    memberData: [],
    paymentsByCardType: [],
    paymentsByRegion: [],
    paymentsData: [],
    cardIssueReport: [],
    cardIssueRatioReport: [],
    summary: [
        {
            icon: 'alert',
            color: Color.red,
            title: 'Pending Approval',
            key:"pending_approval",
            number: 0,
        },
        {
            icon: 'clock-circle',
            color: Color.red,
            title: 'Approved',
            number: 0,
            key:"complete_approval"
        },
        {
            icon: 'team',
            color: Color.red,
            title: 'New Members',
            number: 0,
            key:"active_members"
        },
       
        {
            icon: 'wallet',
            color: Color.red,
            title: 'Amount Due',
            number: 0,
            key:"amount_due"
        },
    ],
    loading: false
}
const admin = createSlice({
    name:"admin",
    initialState: initialState,
    reducers:{
      getAdminSummaryStart(state){
          state.loading = true
       },
       getAdminSummarySuccess(state,action){
           const { results } = action.payload
           const numbers = state.summary.map(item => item ? { ...item, number: results[item.key] } : item)
           state.summary = numbers
           state.loading = false
       },
       getAdminSummaryFailure(state, action){
           state.loading = false
       },
        getPaymentsSummary(state, action) {
            state.paymentsData = action.payload
        },
        getPaymentsByCardTypeSuccess(state,action){
            state.paymentsByCardType = action.payload
        },
        getPaymentsByDaySuccess(state,action){
           state.paymentsData = action.payload
        },
        openDrawer(state) {
            state.settingVisible = true
        },
        closeDrawer(state) {
            state.settingVisible = false
        },
        getMemberRatioReportSuccess(state,action){
           state.memberRatioData = action.payload
        },
        getMemberReportSuccess(state,action){
           state.memberData = action.payload
        },
        getCardIssueRatioReportSuccess(state, action){
           state.cardIssueRatioReport = action.payload
        },
        getCardIssueReportSuccess(state, action){
          state.cardIssueReport = action.payload
        }
    }

})
export const {
 getPaymentsByCardTypeSuccess,
 getPaymentsByDaySuccess,
 getPaymentsSummary,
 getAdminSummaryStart,
 getAdminSummarySuccess,
 getAdminSummaryFailure,
 getMemberRatioReportSuccess,
 getMemberReportSuccess,
 getCardIssueReportSuccess,
 getCardIssueRatioReportSuccess,
 openDrawer,
 closeDrawer
} = admin.actions

export default admin.reducer

const createPendingApplicationFilters = (filters,user) =>{
    if (user.is_admin) {
        return { ...filters, is_approved_national: false  }
    } else if (user.is_branch) {
        return { ...filters, is_approved_sub_branch: true,is_approved_national:false }
    } else if (user.is_subBranch) {
        return { ...filters, is_approved_sub_branch: false, is_approved_national: false  }
    }
    return filters
}
const createApprovedApplicationFilters = (filters, user) =>{
    if(user.is_admin){
        return {...filters,is_approved_national: true}
    }else if(user.is_branch){
        return {...filters,is_approved_branch: true}
    }else if(user.is_subBranch){
        return {...filters,is_approved_sub_branch: true}
    }else {
        return filters
    }
}
export const fetchSummary = (filters, user) => async (dispatch,getState) =>{
    dispatch(getAdminSummaryStart())
    try {
        const approvedFilters = createApprovedApplicationFilters(filters, user)
        const pendingFilters = createPendingApplicationFilters(filters, user) 
        axios.all([
            axios.get(MEMBER_DASHBAORD_URL, { params: {action: MEMBER_ACTIONS.MEMBER_COUNT,...filters}, headers: tokenConfig(getState).headers }),
            axios.get(APPLICATION_DASHBOARD_URL, { params: {...approvedFilters, action: APPLICATION_ACTIONS.APPLICATION_COUNT}, headers: tokenConfig(getState).headers}),
            axios.get(APPLICATION_DASHBOARD_URL, { params: {...pendingFilters, action: APPLICATION_ACTIONS.APPLICATION_COUNT, ...filters,is_approved_national: false }, headers: tokenConfig(getState).headers }),
            axios.get(APPLICATION_DASHBOARD_URL, { params: { ...filters, action: APPLICATION_ACTIONS.PAYMENTS_DUE }, headers: tokenConfig(getState).headers })
        ]).then(axios.spread((members, approved,pending,payments) => {
            console.log(approved)
            dispatch(getAdminSummarySuccess({results: 
                {
                    active_members: members.data.value,
                    complete_approval: approved.data.value,
                    pending_approval: pending.data.value,
                    amount_due: payments.data.value
                }
            }))
            }));
       
      
    } catch (error) {
        console.log(error)
        dispatch(getAdminSummaryFailure())
    
    }
}

export const fetchPaymentReports = filters => async (dispatch, getState) => {
    try {
        const response = await axios.get(APPLICATION_DASHBOARD_URL, {
            params: filters,
            headers: tokenConfig(getState).headers
        })
        if( filters.action === APPLICATION_ACTIONS.PAYMENTS_BY_CARD_TYPE){
            dispatch(getPaymentsByCardTypeSuccess(response.data))
        }
        else if(filters.action === APPLICATION_ACTIONS.PAYMENTS_BY_DAY){
            dispatch(getPaymentsByDaySuccess(response.data))
        }
        else{
            
        }
    } catch (error) {
         console.log(error)
    }
}
export const fetchMemberReports = filters => async (dispatch,getState) =>{
    try {
        if (
            (MEMBER_ACTIONS.MEMBER_BLOOD_GROUP === filters.action) || 
            (MEMBER_ACTIONS.MEMBER_LEADERSHIP === filters.action) || 
            (MEMBER_ACTIONS.MEMBER_GENDER === filters.action) ||
            (MEMBER_ACTIONS.MEMBER_EMPLOYMENT_STATUS === filters.action)
            ){
            const response = await axios.get(MEMBER_DASHBAORD_URL,{params: filters,headers: tokenConfig(getState).headers})
            dispatch(getMemberRatioReportSuccess(response.data))

        }
        else if (MEMBER_ACTIONS.MEMBER_COUNT_BY_DAY === filters.action){
            const response = await axios.get(MEMBER_DASHBAORD_URL, {
                params: filters,
                headers: tokenConfig(getState).headers
            })
            dispatch(getMemberReportSuccess(response.data))

        }
        else if (MEMBER_ACTIONS.MEMBER_EDUCATION_LEVEL === filters.action){
            const response = await axios.get(EDUCATIONAL_LEVEL_DASHBOARD_URL, {
                params: filters,
                headers: tokenConfig(getState).headers
            })
            dispatch(getMemberRatioReportSuccess(response.data))

        }else if (MEMBER_ACTIONS.MEMBER_TRCS_EXPERIENCE === filters.action){
            const response = await axios.get(TRCS_EXPERIENCE_DASHBOARD_URL, {
                params: filters,
                headers: tokenConfig(getState).headers
            })
            dispatch(getMemberRatioReportSuccess(response.data))

        }else{

        }
    } catch (error) {
        
    }
} 
export const fetchCardIssueReport = filters => async(dispatch, getState) =>{
    try {
        const response = await axios.get(CARD_ISSUED_REPORT_URL, {
            params: filters,
            headers: tokenConfig(getState).headers
        })
        if(filters.action === CARD_ISSUED_ACTIONS.COUNT_BY_DAY){
            dispatch(getCardIssueReportSuccess(response.data))
        }
        if(filters.action === CARD_ISSUED_ACTIONS.COUNT_BY_CARD_TYPE){
            dispatch(getCardIssueRatioReportSuccess(response.data))
        }
    } catch (error) {
        console.log(error)
    }
   
}
