// Member routes

export const MEMBER_DASHBOARD_ROUTE = "/member/dashboard"
export const MEMBER_MYAPPLICATION_URL = '/member/myapplication'
export const MEMBER_PAYMENTS_URL = '/member/mypayments'
export const MEMBER_PROFILE_URL = "/member/profile"
export const MEMBER_PROFILE_UPDATE_URL = "/member/profile/update/"
export const MEMBER_CHANGE_PASSWORD_ROUTE = "/member/change-password"
export const MEMBER_SUBSCRIPTION_ROUTE = "/member/subscription"




// Admin routes
export const ADMIN_DASHBOARD_ROUTE = "/admin/dashboard"
export const ADMIN_APPROVAL_ROUTE = "/admin/approval"
export const ADMIN_MEMBERS_ROUTE = "/admin/members"
export const ADMIN_MEMBER_DETAIL = "/admin/members/detail/"
export const ADMIN_BRANCHES_ROUTE = "/admin/branches"
export const ADMIN_SUB_BRANCHES_ROUTE = "/admin/sub-branches"
export const ADMIN_EMPLOYEES_ROUTE = "/admin/employees"
export const ADMIN_APPLICATIONS_ROUTE = "/admin/applications"
export const ADMIN_REGIONS_ROUTE = "/admin/regions"
export const ADMIN_DISTRICTS_ROUTE = "/admin/districts"
export const ADMIN_WARDS_ROUTE = "/admin/wards"
export const ADMIN_PLANS_ROUTE = "/admin/plans"
export const ADMIN_LEADERSHIPS_ROUTE = "/admin/leaderships"
export const ADMIN_EXPERIENCES_ROUTE = "/admin/experiences"
export const ADMIN_PAYMENTS_ROUTE = "/admin/payments"
export const ADMIN_CHANGE_PASSWORD_ROUTE = "/admin/change-password"
export const APPROVAL_DETAIL = "/admin/approval/detail"
export const SUBSCRIPTION_ROUTE = "/admin/subscription"

// Other routes
export const LOGIN_ROUTE = "/login"
export const MEMBER_REGISTRATION_ROUTE = "/registration"
export const PASSWORD_RESET = "/password-reset"




export const admin_routes = [
    {
        id: 1,
        icon: "dashboard",
        route: "/admin/dashboard",
        name: "Dashboard",
        is_subBranch:true,
        is_branch:true

    },
    {
        id: 2,
        icon: "audit",
        route: "/admin/approval",
        name: "Approval",
        is_subBranch: true,
        is_branch: true
    },
    {
        id: 3,
        icon: "team",
        route: "/admin/members",
        name: "Members",
        is_subBranch: true,
        is_branch: true
    },
    {
        id: 4,
        icon: "branches",
        route: "/admin/branches",
        name: "Branches",
        is_subBranch: false,
        is_branch: false
    },
    {
        id: 5,
        icon: "branches",
        route: "/admin/sub-branches",
        name: "Sub branches",
        is_subBranch: false,
        is_branch: false
    },
    {
        id: 6,
        icon: "team",
        route: "/admin/employees",
        name: "Employees",
        is_subBranch: false,
        is_branch: false
    },
    {
        id: 7,
        icon: "pic-right",
        route: "/admin/applications",
        name: "Applications",
        is_subBranch: true,
        is_branch: true
    },
    {
        id: 8,
        icon: "pic-right",
        route: "/admin/subscription",
        name: "Subscription",
        is_subBranch: false,
        is_branch: false
    },
    {
        id: 9,
        icon: "dollar",
        route: "/admin/payments",
        name: "Payments",
        is_subBranch: true,
        is_branch: true
    },
    {
        id: 10,
        icon: "fork",
        route: "/admin/regions",
        name: "Regions",
        is_subBranch: false,
        is_branch: false
    },
    {
        id: 11,
        icon: "radius-upleft",
        route: "/admin/districts",
        name: "Districts", 
        is_subBranch: false,
        is_branch: false
    },
    {
        id: 12,
        icon: "radius-bottomright",
        route: "/admin/wards",
        name: "Wards",
        is_subBranch: false,
        is_branch: false
    },
    {
        id: 13,
        icon: "retweet",
        route: "/admin/plans",
        name: "Plans",
        is_subBranch: false,
        is_branch: false
    },
    {
        id: 14,
        icon: "user",
        route: "/admin/leaderships",
        name: "Leadership",
        is_subBranch: false,
        is_branch: false
    },
    {
        id: 15,
        icon: "user",
        route: "/admin/experiences",
        name: "Experience",
        is_subBranch: false,
        is_branch: false
    },
    {
        id: 16,
        icon: "lock",
        route: "/admin/change-password",
        name: "Change password",
        is_subBranch: true,
        is_branch: true
    },
]
export const member_routes= [
    {
        id: 1,
        icon: "dashboard",
        route:"/member/dashboard",
        name:"Dashboard"
    },
    {
        id: 2,
        icon: "user",
        route: "/member/subscription",
        name: "My subscription"
    },
    {
        id: 3,
        icon: "user",
        route:"/member/profile",
        name:"Profile"
    },
    {
        id: 4,
        icon: "wallet",
        route:"/member/myapplication",
        name:"My Application"
    },
    {
        id: 5,
        icon: "dollar",
        route:"/member/mypayments",
        name:"My Payments"
    },
    {
        id: 6,
        icon: "lock",
        route: "/member/change-password",
        name: "Change password"
    },
    // {
    //     id: 4,
    //     icon: "wallet",
    //     route:"/payment",
    //     name:"Payments"
    // },
    // {
    //     id: 4,
    //     icon: "setting",
    //     route:"/settings",
    //     name:"Account settings"
    // },
]