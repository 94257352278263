import {notification} from 'antd'
export const errorHandler = err =>{
    if(err.response && err.response.data){
        const {data} = err.response
        console.log(data)
        if (Array.isArray(data)){
            return data
        }
        if (typeof data === "object"){
            const { detail } =  data
            if (detail) {
                return [detail]
            }
            let messages = []
            const keys = Object.keys(data)
            keys.forEach(key => {
                const temp = data[key]
                if (key === "user") {
                    if (temp.email) {
                        messages.push(`Email: ${(temp.email.join(","))}`)
                    } else if (temp.password) {
                        messages.push(`Password: ${(temp.password.join(","))}`)
                    } else if (temp.username) {
                        messages.push(`Username: ${(temp.username.join(","))}`)
                    } else {
                        messages.push(`Avatar: ${(temp.avatar.join(","))}`)
                    }
                } else {
                    messages.push(`${key}: ${temp.join(',')}`)
                }
            })
            return messages
            
        }else{
            return [err.message]
        }
              
    } else if (err.request) {
            return ["Oops! Check your internet connections"]

    } else {
    // Something happened in setting up the request that triggered an Error
    return [err.message]
    }
}
export const notifyUser = (type, message,description) => {
    notification[type]({
        message: message,
        description: description
    });
};
export const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'TSH',
});
export const Constant = {
    Color: {
        green: '#64ea91',
        blue: '#8fc9fb',
        purple: '#d897eb',
        red: '#E53B2D',
        yellow: '#f8c82e',
        peach: '#f797d6',
        borderBase: '#e5e5e5',
        borderSplit: '#f4f4f4',
        grass: '#d6fbb5',
        sky: '#c1e0fc',
    },
}
