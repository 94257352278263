import { createSlice } from 'redux-starter-kit'

const initialState = {
    currentStep: 0
}
const registerSlice = createSlice({
    name: "register",
    initialState: initialState,
    reducers: {
        nextStep(state){
          state.currentStep = state.currentStep + 1
        },
        prevStep(state){
            state.currentStep = state.currentStep - 1
        }
    }
})
export default registerSlice.reducer

export const {
   nextStep,
   prevStep
} = registerSlice.actions