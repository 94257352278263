import { createSlice } from "redux-starter-kit";
import { errorHandler, notifyUser } from "../util";
import { tokenConfig } from "../pages/login/loginSlice";
import moment from "moment";
import {
  getApplications,
  getCompletedApps,
  exportApps,
  bulkApprove,
} from "../services/application";
const initialState = {
  loading: false,
  exporting: false,
  filter: {},
  apps: [],
  completedApps: [],
  errors: [{message: null, code: null}],
  count: 0,
};

const apps = createSlice({
  initialState: initialState,
  name: "apps",
  reducers: {
    setLoading(state) {
      state.loading = true;
    },
    getAppsSuccess(state, action) {
      const { count, apps } = action.payload;
      state.loading = false;
      state.apps = apps;
      state.count = count;
    },
    getCompletedAppsSuccess(state, action) {
      state.loading = false;
      state.completedApps = action.payload;
    },
    exportAppsStart(state) {
      state.exporting = true;
    },
    exportAppsSuccess(state) {
      state.exporting = false;
    },
    exportAppsFailure(state) {
      state.exporting = false;
    },
    crudeAppsFailure(state, action) {
      state.loading = false;
      state.errors = action.payload;
      notifyUser("error", "Error", action.payload);
    },
    approveStart(state, action) {
      state.loading = true;
      
    },
    resetErrors(state, action){
      state.errors = [{ message: null, code: null }];
    },
    approveSuccess(state, action) {
      const { success } = action.payload;
      state.apps = state.apps.filter((item) => !success.includes(item.id));
      state.loading = false;
    },
    approveFailure(state, action) {
      state.loading = false;
      const { status_code, message } = action.payload;
      if (status_code === 503) {
        state.errors = [message];
      } else {
        notifyUser("error", "Error", message);
      }
    },
  },
  approveFailure(state, action) {
    state.loading = false;
  }
});
export default apps.reducer;
export const {
  setLoading,
  getAppsSuccess,
  getCompletedAppsSuccess,
  exportAppsStart,
  exportAppsFailure,
  exportAppsSuccess,
  crudeAppsFailure,
  approveStart,
  approveSuccess,
  approveFailure,
  resetErrors
} = apps.actions;
export const fetchApps = (filters = { type: "get" }) => async (
  dispatch,
  getState
) => {
  dispatch(setLoading());
  try {
    const { type, ...rest } = filters;
    if (type === "get" || "search") {
      const { apps, count } = await getApplications(
        rest,
        tokenConfig(getState)
      );
      dispatch(getAppsSuccess({ apps, count }));
    } else {
    }
  } catch (error) {
    const err = errorHandler(error);
    dispatch(crudeAppsFailure(err));
  }
};
export const fetchCompletedApps = (filters) => async (dispatch, getState) => {
  dispatch(setLoading());
  try {
    const res = await getCompletedApps(filters, tokenConfig(getState));
    dispatch(getCompletedAppsSuccess(res));
  } catch (error) {
    const err = errorHandler(error);
    dispatch(crudeAppsFailure(err));
  }
};
export const handleExportApps = (payload) => async (dispatch, getState) => {
  dispatch(exportAppsStart());
  try {
    const data = await exportApps(payload, tokenConfig(getState));
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `applications-${moment()}.xlsx`);
    document.body.appendChild(link);
    link.click();
    dispatch(exportAppsSuccess());
  } catch (error) {
    dispatch(exportAppsFailure());
  }
};

export const approve = (level, payload) => async (dispatch, getState) => {
  dispatch(approveStart());
  try {
    const res = await bulkApprove(level, payload, tokenConfig(getState));
    dispatch(approveSuccess(res));
    return Promise.resolve(res);
  } catch (error) {
    const response = handleErrors(error);
    dispatch(approveFailure(response));
    return Promise.reject();
  }
};
export const resetAppErrors = () => async (dispatch) =>{
  try {
    dispatch(resetErrors())
  } catch (error) {
  }
}
const handleErrors = (err, validationErrorHandler) => {
  let errors = null;
  if (err && err.response) {
    errors = handle_400_500(err, validationErrorHandler);
  } else if (err && err.request) {
    errors = {
      status_code: null,
      message: "Please check internet connection and try again.",
    };
  } else {
    errors = { status_code: null, message: "An error has occured." };
  }
  return errors;
};
const handle_400_500 = (err, validationErrorHandler) => {
  let errors = null;
  const { detail } = err.response.data;
  if (detail) {
    errors = { status_code: err.response.status, message: detail };
  } else if (err.response.status === 400) {
    if (validationErrorHandler) {
      errors = validationErrorHandler(err.response.data);
    } else {
      errors = { code: 400, message: "Bad request" };
    }
  } else {
    errors = {
      status_code: err.response.status,
      message: "Internal server error.",
    };
  }
  return errors;
};
