// export const DOMAIN =  "http://localhost:8000"
export const DOMAIN = 'https://rcmms-backened.trcs.or.tz'
// export const DOMAIN = "http://local:8000"
export const REGISTER_MEMBER_URL = "/api/next-member-api/";
export const LOGIN_URL = "/api/auth/login"
export const GET_USER_URL = "/api/auth/user"
export const LOGOUT_USER_URL = "/api/auth/logout"
export const GET_MEMBER_URL = "/api/member"
export const MEMBER_URL = "/api/next-member-api";
export const COUNTRY_URL = "/api/countries/"
export const REGION_URL = "/api/regions/"
export const DISTRICT_URL = "/api/districts/"
export const WARD_URL = "/api/wards/"
export const BRANCH_URL = "/api/branches/"
export const SUB_BRANCH_URL = "/api/sub-branches/"
export const GET_NIDA_USER = "/api/nidadata/"


export const LEADERSHIP_URL = "/api/leadership/"

export const TRCS_EXPERIENCE_URL = "/api/trcs-experiences/"
export const EXPERIENCE_URL = "/api/experiences/"

export const EDUCATIONAL_LEVEL_URL = "/api/education-levels"
export const EMPLOYMENT_STATUS_URL = "/api/employment-statuses"
export const APPROVAL_MEMBER_URL = "/api/auth/approve"
export const BULK_APPROVAL_URL = "/api/bulk-approval/";
export const NOTIFICATION_URL = "/api/notifications/"
export const APPLICATION_URL = "/api/applications"
// export const ADMIN_APPLICATION_URL = "api/admin/applications/"
export const RESIDENCE_URL = '/api/residence'
export const MEMBER_DASHBOARD_URL = "/api/member-dashboard/"
export const EXPORT_MEMBER_DETAILS = "/api/export-members"

export const ADMIN_DASHBOARD_URL = "/api/admin-dashboard/summary"

export const UPLOAD_PROFILE = "/api/auth/upload-profile"
export const PLANS_URL = '/api/payment-types/'
export const PAYMENTS_URL = "/api/payments-list/"
export const SAMPLE_CSV_URL = `${DOMAIN}/media/samples/sample.csv`
export const SAMPLE_EMPLOYEES_URL = `${DOMAIN}/media/samples/sampleEmployees.csv`
export const SAMPLE_WARDS_URL = `${DOMAIN}/media/samples/sampleWards.csv`
export const MEMBER_UPDATE_URL = "/api/members/update/"
export const BEGIN_PASSWORD_RESET = "/api/accounts/begin_reset"
export const VERIFY_TOKEN = "/api/accounts/verify"
export const PASSWORD_RESET = "/api/accounts/reset_password"
export const CHANGE_PASSWORD_URL = "/api/auth/change-password"
export const EMPLOYEE_URL = "/api/employees/"
export const IMPORT_EMPLOYEES_URL = "/api/import-employees/"
export const IMPORT_WARDS_URL = "/api/import-wards/"
export const EXPORT_MEMBERS_URL= `${DOMAIN}/api/member-export/`
export const EXPORT_APPLICATIONS_URL= `${DOMAIN}/api/application-export/`
export const EXPORT_BRANCHES_URL= `${DOMAIN}/api/branch-export/`
export const EXPORT_SUB_BRANCHES_URL= `${DOMAIN}/api/sub-branch-export/`
export const EXPORT_PAYMENTS_URL = `${DOMAIN}/api/payment-export/`
export const IDCARDS_URL = "/api/idcards/"

export const SUBSCRIPTION_URL = "/api/subscription/"
export const UPGRADE_SUBSCRIPTION_URL = "/api/subscription/upgrade/"
export const DOCUMENT_URL = "/api/subscription/documents"
export const GENERATE_MEMBERSHIP_CARD_URL = "/api/subscription/generate_membership_card/"
export const MEMBERSHIP_CARD_DOWNLOAD_URL = `${DOMAIN}/api/downloads/download_card_pdf`
export const PLAN_HISTORY_URL ="/api/plan-history/"
export const CARD_ISSUE_HISTORY = "/api/card-issue-history/"

export const APPLICATION_DASHBOARD_URL = "/api/dashboard/application/"
export const PAYMENT_DASHBOARD_URL = "/api/dashboard/payments/"
export const MEMBER_DASHBAORD_URL = "/api/dashboard/member/"
export const TRCS_EXPERIENCE_DASHBOARD_URL = "/api/dashboard/trcs-experience/"
export const EDUCATIONAL_LEVEL_DASHBOARD_URL = '/api/dashboard/education-level'
export const CARD_ISSUED_REPORT_URL = "/api/reports/card-issue-history"


