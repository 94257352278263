import { createSlice } from "redux-starter-kit";
import {getOrders, getOrderLines, getSubscriptions, postOrder, putOrder} from './api'
import {errorHandler, notifyUser} from '../../util'
import { tokenConfig } from "../login/loginSlice";
const initialState = {
  orders: [],
  order: null,
  lines: [],
  subscriptions: [],
  subscriptionsCount: 0,
  loading: false,
  updating: false,
  subscriptionLoading: false,
  count: 0,
};
const card = createSlice({
  name: "card",
  initialState: initialState,
  reducers: {
    createOrderStart(state){
       state.loading = true
    },
    createOrderSuccess(state, action){
      state.loading = false
      state.order = action.payload
      state.orders.push(action.payload)
    },
    createOrderFailure(state){
       state.loading = false;
    },
    getOrdersStart(state) {
      state.loading = true;
    },
    getOrdersSuccess(state, action) {
      state.orders = action.payload.results;
      state.count = action.payload.count;
      state.loading = false;
    },
    getOrdersFailure(state, action) {
      state.loading = false;
    },
    getOrderLinesStart(state) {
      state.loading = true;
    },
    getOrderLinesSuccess(state, action) {
      const {lines, ...order} = action.payload
      state.order = order;
      state.lines = lines
      state.loading = false;
    },
    getOrderLinesFailure(state, action) {
      state.loading = false;
    },
    // subscriptions
    getSubscriptionsStart(state, action){
       state.subscriptionLoading = true
    },
    getSubscriptionFailure(state, action){
       state.subscriptionLoading = false
    },
    getSubscriptionSuccess(state, action){
       state.subscriptions = action.payload.results
       state.subscriptionsCount = action.payload.count
       state.subscriptionLoading = false
    },
    updateOrderStart(state){
       state.updating = true
    },
    updateOrderSuccess(state, action){
       state.updating = false
       state.order = action.payload
    },
    updateOrderFailure(state, action){
       state.updating = false
    },
    

  },
});

export const {
  getOrdersStart,
  getOrdersSuccess,
  getOrdersFailure,
  getOrderLinesStart,
  getOrderLinesSuccess,
  getOrderLinesFailure,
  createOrderStart,
  createOrderSuccess,
  createOrderFailure,
  updateOrderStart,
  updateOrderSuccess,
  updateOrderFailure,

  // subscriptions
  getSubscriptionsStart,
  getSubscriptionSuccess,
  getSubscriptionFailure
} = card.actions;
export default card.reducer;
export const fetchOrders = (filters) => async (dispatch,getState) => {
  dispatch(getOrdersStart());
  try {
    const response = await getOrders(filters, tokenConfig(getState))
    dispatch(getOrdersSuccess(response));
  } catch (error) {
      console.log(error)
    dispatch(getOrdersFailure());
  }
};
export const fetchOrderLines = (order_id) => async (dispatch,getState) => {
  dispatch(getOrderLinesStart());
  try {
 
    const response = await getOrderLines(order_id, tokenConfig(getState));
    console.log(response)
    dispatch(getOrderLinesSuccess(response));
  } catch (error) {
      console.log(error)
    dispatch(getOrderLinesFailure());
  }
};

export const fetchSubscriptions = (filters) => async (dispatch, getState) => {
  dispatch(getSubscriptionsStart());
  try {
    const response = await getSubscriptions(filters, tokenConfig(getState));
    dispatch(getSubscriptionSuccess(response));
  } catch (error) {
    console.log(error);
    dispatch(getSubscriptionFailure());
  }
};
export const createOrder = (payload) => async (dispatch, getState) => {
  dispatch(createOrderStart());
  try {
    const response = await postOrder(payload, tokenConfig(getState));
    dispatch(createOrderSuccess(response));
  } catch (error) {
     const errorMessages = errorHandler(error)
     notifyUser("error", "Error", errorMessages[0])
    dispatch(createOrderFailure());
    return Promise.reject()
  }
};
export const updateOrder = (order_id, payload) => async (dispatch, getState) => {
  dispatch(updateOrderStart());
  try {
    const response = await putOrder(order_id, payload, tokenConfig(getState));
    dispatch(updateOrderSuccess(response));
  } catch (error) {
     const errorMessages = errorHandler(error)
     notifyUser("error", "Error", errorMessages[0])
    dispatch(updateOrderFailure());
    return Promise.reject()
  }
};
