import axios from 'axios'
import {
    REGION_URL,
    WARD_URL,
    DISTRICT_URL,
    RESIDENCE_URL
} from '../urls'
// RESIDENCE 
export async function getResidences() {
    const { data } = await axios.get(RESIDENCE_URL)
    const {residence} =  data
    return residence

}
// Regions API
export async function addRegion(payload) {
    const { data } = await axios.post(REGION_URL,payload)
    return data
}
export async function updateRegion(id, payload) {
    const { data } = await axios.put(`${REGION_URL}${id}/`, payload)
    return data
}
export async function getRegions() {
    const { data } = await axios.get(REGION_URL)
    const {results, count} = data
    return {
        regions: results,
        count: count
    }
}
export async function searchRegions(query){
    const { data } = await axios.get(`${REGION_URL}?search=${query}`)
    const { results, count } = data
    return {
        regions: results,
        count: count
    }
}
export async function deleteRegion(query) {
    const { data } = await axios.delete(`${REGION_URL}${query}/`)
    return {
        region: data
    }
}
// Wards API

export async function addWard(payload) {
    const { data } = await axios.post(WARD_URL, payload)
    return data
}
export async function getWards(filters) {
    const { data } = await axios.get(WARD_URL,{
        params: filters
    })
    const { results, count } = data
    return {
        wards: results,
        count: count
    }
}
export async function searchWards(query) {
    const { data } = await axios.get(`${WARD_URL}?search=${query}`)
    const { results, count } = data
    return {
        wards: results,
        count: count
    }
}
export async function updateWard(id, payload) {
    const { data } = await axios.put(`${WARD_URL}${id}/`, payload)
    return data
}
export async function deleteWard(query) {
    const { data } = await axios.delete(`${WARD_URL}${query}/`)
    return {
        ward: data
    }
}




//Districts API
export async function addDistrict(payload) {
    const { data } = await axios.post(DISTRICT_URL, payload)
    return data
}
export async function getDistricts(filters) {
    const { data } = await axios.get(DISTRICT_URL,{
        params: filters
    })
    const { results, count} = data
    return {
        districts: results,
        count: count
    }
}

export async function searchDistricts(query) {
    const { data } = await axios.get(`${DISTRICT_URL}?search=${query}`)
    const { results, count } = data
    return {
        districts: results,
        count: count
    }
}
export async function updateDistrict(id, payload) {
    const { data } = await axios.put(`${DISTRICT_URL}${id}/`, payload)
    return data
}
export async function deleteDistrict(query) {
    const { data } = await axios.delete(`${DISTRICT_URL}${query}/`)
    return {
        region: data
    }
}



// 