import { createSlice } from 'redux-starter-kit'
import axios from 'axios';
import { APPLICATION_URL} from '../../../urls'
import { tokenConfig} from '../../../pages/login/loginSlice'

const inititialState = {
    applications: [],
    loading: false,
    errors: {}
}
const apps = createSlice({
     name:"apps",
     initialState: inititialState,
     reducers:{
        getMemberAppStart(state){
           state.loading = true
        },
        getMemberAppSuccess(state, action){
            const {results} = action.payload
            state.loading = false
            state.applications = results
        },
        getMemberAppFailure(state,action){
            const { errors } = action.payload
            state.loading = false
            state.applications = errors
        }
    }
})
export const {
   getMemberAppStart,
   getMemberAppSuccess,
   getMemberAppFailure
} = apps.actions
export default apps.reducer
export const fetchMemberApps = () => async (dispatch, getState) =>{
    dispatch(getMemberAppStart())
    try {
        const response = await axios.get(APPLICATION_URL,tokenConfig(getState))
        const {results} = response.data
        console.log(results)
        dispatch(getMemberAppSuccess({ results}))
    } catch (error) {
        if(error && error.response){
            dispatch(getMemberAppFailure({ errors:[]}))
        }
      
    }
}