import axios from 'axios'
import {
    SUBSCRIPTION_URL,
    GENERATE_MEMBERSHIP_CARD_URL,
    UPGRADE_SUBSCRIPTION_URL,
    DOCUMENT_URL

} from '../urls'


// BRANCH 
export async function getSubscriptions(filters,config) {
    const { data } = await axios.get(SUBSCRIPTION_URL, {
        params: filters,
        headers: config.headers
    })
    const { count, results } = data
    return {
        count: count,
        subscriptions: results
    }
}
export async function postMembershipGenerationRequest(payload,config){
    const { data } = await axios.post(GENERATE_MEMBERSHIP_CARD_URL,payload,config)
    const { hash } = data
    return hash
}
export async function postUpgradeMembership(payload, config){
    const {data} = await axios.post(UPGRADE_SUBSCRIPTION_URL,payload,config)
    return data
}
export async function getDocuments(filters, config){
    const {data} = await axios.get(DOCUMENT_URL,{
        params:filters,
        headers: config.headers
    })
    return data
}
