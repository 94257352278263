import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import register_en from "./transalations/en/register";
import register_sw from "./transalations/sw/register";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
    en: {
        translation: register_en
    },
    sw: {
        translation: register_sw
    },
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "sw",

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;