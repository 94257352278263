import { combineReducers } from 'redux-starter-kit'
import DistrictReducer from './slices/districtSlice'
import RegionReducer from './slices/regionSlice'
import WardReducer from './slices/wardSlice'
import PlanReducer from './slices/planSlice'
import PaymentReducer from './pages/payments/paymentSlice'
import BranchReducer from './slices/branchSlice'
import SubReducer from './slices/subBranchSlice'
import LoginReducer from './pages/login/loginSlice'
import HistoryReducer from './slices/historySlice'
import MemberReducer from './slices/memberSlice'
import AppsReducer from './slices/applicationSlice'
import AdminAppsReducer from './pages/dashboard/admin/adminSlice'
import EmployeeReducer from './pages/employees/employeeSlice'
import LeadershipReducer from './slices/leadershipSlice'
import ExperienceReducer from './slices/experienceSlice'
import NotificationReducer from './slices//nofiticationSlice'
import memberDashboardReducer from './pages/dashboard/member/dashBoardSlice'
import memberAppsReducer from './pages/applications/member/applicationSlice'
import AccountReducer from './pages/accounts/accountSlice'
import SubscriptionReducer from './slices/subscription'
import VisibilityReducer from './slices/visibleFiltersSlice'
import RegisterReducer from './slices/registerSlice';
import LogReducer from './pages/logs/logSlice';
import CardReducer from './pages/cards/cardSlice';

const rootReducer = combineReducers({
    district: DistrictReducer,
    region: RegionReducer,
    ward: WardReducer,
    plan: PlanReducer,
    payment: PaymentReducer,
    branch: BranchReducer,
    sub_branch: SubReducer,
    login: LoginReducer,
    location: HistoryReducer,
    member: MemberReducer,
    apps: AppsReducer,
    admin: AdminAppsReducer,
    employee:EmployeeReducer,
    leadership: LeadershipReducer,
    experience: ExperienceReducer,
    notification: NotificationReducer,
    memberDashboard: memberDashboardReducer,
    memberApps: memberAppsReducer,
    account: AccountReducer,
    subscription: SubscriptionReducer,
    visibilityFilter: VisibilityReducer,
    register: RegisterReducer,
    log: LogReducer,
    card: CardReducer
    
})
export default rootReducer

