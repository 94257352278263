import { createSlice } from 'redux-starter-kit'
import { errorHandler, notifyUser } from '../util'
import {
    getWards,
    searchWards,
    addWard,
    updateWard,
    deleteWard
} from '../services/location'
const initialState = {
    loading: false,
    filter: {},
    wards: [],
    modal: {
        modalType: "add",
        visible: false,
        currentWard: {}
    },
    import_modal:{
        visible: false
    },
    errors: [],
    count: 0,
}

const ward = createSlice({
    initialState: initialState,
    name: "ward",
    reducers: {
        setLoading(state) {
            state.loading = true
        },
        getWardsSuccess(state, action) {
            const { count, wards } = action.payload
            state.loading = false
            state.wards = wards
            state.count = count
        },
        updateWardSuccess(state, action) {
            let description = ""
            state.loading = false
            state.modal.visible = false
            const { ward, type } = action.payload
            if (type === "update") {
                const wards = state.wards.map(wad => wad.id === ward.id ? ward : wad)
                state.wards = wards
                description = "One ward was successfully updated"
            } else if (type === "add") {
                state.wards = [...state.wards, ward]
                description = "One ward was successfully added"
            } else {
                const { ward_id } = action.payload
                const wards = state.wards.filter(ward => ward.id !== ward_id)
                state.wards = wards
                description = "One ward was successfully deleted"
            }
            notifyUser("success", "Info", description)
        },
        crudeWardsFailure(state, action) {
            state.loading = false
            state.errors = action.payload
            notifyUser("error", "Error", action.payload)
        },
        hideModal(state) {
            state.modal = initialState.modal
        },
        showModal(state, action) {
            state.modal = {
                modalType: action.payload && action.payload.modalType ? action.payload.modalType : "add",
                visible: true,
                currentWard: action.payload && action.payload.currentWard ? action.payload.currentWard : {}
            }
        },
        showImportModal(state, action) {
            state.import_modal.visible = true
        },
        hideImportModal(state, action) {
            state.import_modal.visible = false
        }
    }
})
export default ward.reducer

export const {
    setLoading,
    getWardsSuccess,
    updateWardSuccess,
    crudeWardsFailure,
    showModal,
    hideModal,
    showImportModal,
    hideImportModal
} = ward.actions
export const fetchWards = (filters = { type: "get" }) => async (dispatch) => {
    dispatch(setLoading())
    try {
        const {type, ...rest} = filters
        if (type === "get") {
            const { wards, count } = await getWards(rest)
            dispatch(getWardsSuccess({ wards, count }))
        } else if (type === "search") {
            const { wards, count } = await searchWards(rest.query)
            dispatch(getWardsSuccess({ wards, count }))
        } else {

        }
    } catch (error) {
        const err = errorHandler(error)
        dispatch(crudeWardsFailure(err))
    }
}
export const mutateWards = action => async (dispatch) => {
    dispatch(setLoading())
    try {
        if (action.type === "add") {
            const ward = await addWard(action.payload)
            dispatch(updateWardSuccess({ type: "add", ward: ward }))
        } else if (action.type === "update") {
            const ward = await updateWard(action.query, action.payload)
            dispatch(updateWardSuccess({ type: "update", ward: ward }))
        } else {
            await deleteWard(action.query)
            dispatch(updateWardSuccess({ type: "delete", ward_id: action.query }))
        }

    } catch (error) {
        const err = errorHandler(error)
        dispatch(crudeWardsFailure(err))
    }
}
