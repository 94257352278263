import { createSlice} from 'redux-starter-kit'

import {
  ADMIN_DASHBOARD_ROUTE,
  ADMIN_APPROVAL_ROUTE,
  ADMIN_MEMBERS_ROUTE,
  ADMIN_EMPLOYEES_ROUTE,
  ADMIN_BRANCHES_ROUTE,
  ADMIN_SUB_BRANCHES_ROUTE,
  ADMIN_APPLICATIONS_ROUTE,
  ADMIN_REGIONS_ROUTE,
  ADMIN_WARDS_ROUTE,
  ADMIN_DISTRICTS_ROUTE,
  ADMIN_PLANS_ROUTE,
  ADMIN_LEADERSHIPS_ROUTE,
  ADMIN_EXPERIENCES_ROUTE,
  ADMIN_MEMBER_DETAIL,
  ADMIN_PAYMENTS_ROUTE,
  ADMIN_CHANGE_PASSWORD_ROUTE,
  SUBSCRIPTION_ROUTE,

  // MEMBER ROUTES
 MEMBER_DASHBOARD_ROUTE,
 MEMBER_PROFILE_URL,
 MEMBER_PROFILE_UPDATE_URL,
 MEMBER_MYAPPLICATION_URL,
 MEMBER_PAYMENTS_URL,
 MEMBER_CHANGE_PASSWORD_ROUTE,
 MEMBER_SUBSCRIPTION_ROUTE 
 



} from '../navigations'

const initialState = {selected:0,pathname:""}
const location = createSlice({
    initialState:initialState,
    name:"location",
    reducers:{
         locationChange(state,action){
             const path = action.payload.substring(1)
             state.pathname = path
           switch(path){
              case ADMIN_DASHBOARD_ROUTE:
              case MEMBER_DASHBOARD_ROUTE:
                  state.selected = "1"
                  break
              case ADMIN_APPROVAL_ROUTE:
               case MEMBER_SUBSCRIPTION_ROUTE:
                   state.selected = "2"
                   break
               case ADMIN_MEMBERS_ROUTE:
               case MEMBER_PROFILE_URL:
               case MEMBER_PROFILE_UPDATE_URL:
              
                   state.selected = "3"
                   break
               case ADMIN_BRANCHES_ROUTE:
               case ADMIN_MEMBER_DETAIL:
               case MEMBER_MYAPPLICATION_URL:
             
                    state.selected = "4"
                    break
               case ADMIN_SUB_BRANCHES_ROUTE:
               case MEMBER_PAYMENTS_URL:
                   state.selected = "5"
                   break
               case ADMIN_EMPLOYEES_ROUTE:
               case MEMBER_CHANGE_PASSWORD_ROUTE:
                   state.selected = "6"
                   break
               case ADMIN_APPLICATIONS_ROUTE:
                   state.selected = "7"
                   break
               case SUBSCRIPTION_ROUTE:
                   state.selected = "8"
                   break
               case ADMIN_PAYMENTS_ROUTE:
                   state.selected = "9"
                   break
               case ADMIN_REGIONS_ROUTE:
                   state.selected = "10"
                   break
               case ADMIN_DISTRICTS_ROUTE:
                   state.selected = "11"
                   break
               case ADMIN_WARDS_ROUTE:
                   state.selected = "12"
                   break
               case ADMIN_PLANS_ROUTE:
                   state.selected = "13"
                   break
               case ADMIN_EXPERIENCES_ROUTE:
                   state.selected = "14"
                   break
               case ADMIN_LEADERSHIPS_ROUTE:
                   state.selected = "15"
                   break
             
               case ADMIN_CHANGE_PASSWORD_ROUTE:
                   state.selected = "15"
                   break
              default:
                  break

           }
         }
    }
})
export default location.reducer
export const {
   locationChange
} = location.actions