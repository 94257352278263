import axios from 'axios'
import {
    EXPERIENCE_URL,
    TRCS_EXPERIENCE_URL
} from '../urls'



export async function getExperiences() {
    const { data } = await axios.get(EXPERIENCE_URL)
    const { count, results } = data
    return {
        count: count,
        experiences: results
    }
}

export async function getTrcsExperiences(filters) {
    const { data } = await axios.get(TRCS_EXPERIENCE_URL,{
        params: filters
    })
    const { count, results } = data
    return {
        count: count,
        trcs_experiences: results
    }
}


export async function addTrcsExperience(payload) {
    const { data } = await axios.post(TRCS_EXPERIENCE_URL, payload)
    return data
}
export async function updateTrcsExperience(id, payload) {
    const { data } = await axios.put(`${TRCS_EXPERIENCE_URL}${id}/`, payload)
    return data
}
export async function searchTrcsExperiences(query) {
    const { data } = await axios.get(`${TRCS_EXPERIENCE_URL}?search=${query}`)
    const { results, count } = data
    return {
        trccs_experiences: results,
        count: count
    }
}
export async function deleteTrcsExperience(query) {
    await axios.delete(`${TRCS_EXPERIENCE_URL}${query}/`)
   
}