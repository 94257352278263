import axios from 'axios'
import {
    BRANCH_URL,SUB_BRANCH_URL,
    EXPORT_BRANCHES_URL,
    EXPORT_SUB_BRANCHES_URL

} from '../urls'


// BRANCH 
export async function getBranches(filters) {
    const { data } = await axios.get(BRANCH_URL,{
        params:filters
    })
    const { count, results } = data
    return {
        count: count,
        branches: results
    }
}
export async function addBranch(payload) {
    const { data } = await axios.post(BRANCH_URL, payload)
    return data
}
export async function updateBranch(id, payload) {
    const { data } = await axios.put(`${BRANCH_URL}${id}/`, payload)
    return data
}
export async function searchBranches(query) {
    const { data } = await axios.get(`${BRANCH_URL}?search=${query}`)
    const { results, count } = data
    return {
        branches: results,
        count: count
    }
}
export async function deleteBranch(query) {
    const { data } = await axios.delete(`${BRANCH_URL}${query}/`)
    return {
        branches: data
    }
}
export async function exportBranches(payload, config) {
    const response = await axios.get(EXPORT_BRANCHES_URL, {
        headers: config.headers,
        params: payload,
        responseType: 'blob'

    })
    return response.data
}
export async function exportSubBranches(payload, config) {
    const response = await axios.get(EXPORT_SUB_BRANCHES_URL, {
        headers: config.headers,
        params: payload,
        responseType: 'blob'

    })
    return response.data
}


// SUB BRANCH
export async function getSubBranches(filters) {
    const { data } = await axios.get(SUB_BRANCH_URL,{
        params: filters
    })
    const { count, results } = data
    return {
        count: count,
        sub_branches: results
    }
}
export async function addSubBranch(payload) {
    const { data } = await axios.post(SUB_BRANCH_URL, payload)
    return data
}
export async function updateSubBranch(id, payload) {
    const { data } = await axios.put(`${SUB_BRANCH_URL}${id}/`, payload)
    return data
}
export async function searchSubBranches(query) {
    const { data } = await axios.get(`${SUB_BRANCH_URL}?search=${query}`)
    const { results, count } = data
    return {
        sub_branches: results,
        count: count
    }
}
export async function deleteSubBranch(query) {
    const { data } = await axios.delete(`${SUB_BRANCH_URL}${query}/`)
    return {
        sub_branches: data
    }
}