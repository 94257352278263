import { createSlice } from 'redux-starter-kit'
import { errorHandler, notifyUser } from '../util'
import {tokenConfig} from '../pages/login/loginSlice'
import moment from 'moment'
import {
   addSubBranch,
   getSubBranches,
   searchSubBranches,
   updateSubBranch,
   exportSubBranches,
   deleteSubBranch
} from '../services/branch'
const initialState = {
    loading: false,
    exporting: false,
    filter: {},
    sub_branches: [],
    modal: {
        modalType: "add",
        visible: false,
        currentSubBranch: {}
    },
    errors: [],
    count: 0,
}
const sub_branch = createSlice({
    initialState: initialState,
    name: "sub_branch",
    reducers: {
        setLoading(state) {
            state.loading = true
        },
        setExporting(state){
            state.exporting = false
        },
        exportSubBranchesSuccess(state){
            state.exporting = false
        },
        getSubBranchesSuccess(state, action) {
            const { count, sub_branches } = action.payload
            state.loading = false
            state.sub_branches = sub_branches
            state.count = count
        },
        updateSubBranchSuccess(state, action) {
            let description = ""
            state.loading = false
            state.modal.visible = false
            const { sub_branch, type } = action.payload
            if (type === "update") {
                const sub_branches = state.sub_branches.map(b => b.id === sub_branch.id ? sub_branch : b)
                state.sub_branches = sub_branches
                description = "One sub_branch was successfully updated"
            } else if (type === "add") {
                state.sub_branches = [...state.sub_branches, sub_branch]
                description = "One sub_branch was successfully added"
            } else {
                const { sub_branch_id } = action.payload
                const sub_branches = state.sub_branches.filter(sub_branch => sub_branch.id !== sub_branch_id)
                state.sub_branches = sub_branches
                description = "One sub_branch was successfully deleted"
            }
            notifyUser("success", "Info", description)
        },
        crudeSubBranchesFailure(state, action) {
            state.loading = false
            state.errors = action.payload
            notifyUser("error", "Error", action.payload)
        },
        hideModal(state) {
            state.modal = initialState.modal
        },
        showModal(state, action) {
            state.modal = {
                modalType: action.payload && action.payload.modalType ? action.payload.modalType : "add",
                visible: true,
                currentSubBranch: action.payload && action.payload.currentSubBranch ? action.payload.currentSubBranch : {}
            }
        }
    }
})
export default sub_branch.reducer

export const {
    setLoading,
    setExporting,
    exportBranchesSuccess,
    getSubBranchesSuccess,
    updateSubBranchSuccess,
    crudeSubBranchesFailure,
    showModal,
    hideModal
} = sub_branch.actions
export const fetchSubBranches = (filters = { type: "get" }) => async (dispatch) => {
    dispatch(setLoading())
    try {
        const { type,query, ...rest } = filters
        if (type === "get") {
            const { sub_branches, count } = await getSubBranches(rest)
            dispatch(getSubBranchesSuccess({ sub_branches, count }))
        } else if (type === "search") {
            const { sub_branches, count } = await searchSubBranches(query)
            dispatch(getSubBranchesSuccess({ sub_branches, count }))
        } else {

        }
    } catch (error) {
        const err = errorHandler(error)
        dispatch(crudeSubBranchesFailure(err))
    }
}
export const mutateSubBranches = action => async (dispatch) => {
    dispatch(setLoading())
    try {
        if (action.type === "add") {
            const sub_branch = await addSubBranch(action.payload)
            dispatch(updateSubBranchSuccess({ type: "add", sub_branch: sub_branch }))
        } else if (action.type === "update") {
            const sub_branch = await updateSubBranch(action.query, action.payload)
            dispatch(updateSubBranchSuccess({ type: "update", sub_branch: sub_branch }))
        } else {
            await deleteSubBranch(action.query)
            dispatch(updateSubBranchSuccess({ type: "delete", sub_branch_id: action.query }))
        }

    } catch (error) {
        const err = errorHandler(error)
        dispatch(crudeSubBranchesFailure(err))
    }
}
export const handleExportSubBranches = payload => async (dispatch, getState) => {
    dispatch(setExporting())
    try {
        const data = await exportSubBranches(payload, tokenConfig(getState))
        const url = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement("a")
        link.href = url;
        link.setAttribute('download', `sub-branches-${moment()}.xlsx`);
        document.body.appendChild(link);
        link.click();
        dispatch(exportBranchesSuccess())
    } catch (error) {
        const err = errorHandler(error)
        dispatch(crudeSubBranchesFailure(err))
    }
}

